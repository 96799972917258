import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SpaceCanvas from "../components/SpaceCanvas";
import useIsMobile from "../hooks/useIsMobile";

export default function Space() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onClickContinue = () => {
    navigate("/moon");
  }

  return (
    <div style={{ position: "relative" }}>
      <SpaceCanvas width={isMobile ? 400 : 694} height={546}  style={{ position: "absolute", top: 0 }}/>
      <div style={{ backgroundColor: Colors.Black, position: "absolute", textAlign: "center", top: 120, left: isMobile ? 80 : 220, width: 170, padding: 50, borderRadius: 200 }}>
        <div style={{ color: Colors.Green, marginTop: Sizes.Small }}>
          The space shuttle soars into the cosmos!
        </div>
        <div style={{ color: Colors.Green, marginTop: Sizes.Small, marginBottom: "20px" }}>
          Stars shoot past you as you approach the moon.
        </div>
        <ContinueButton onClick={onClickContinue}>CONTINUE</ContinueButton> 
      </div>
    </div>
  )
}

const ContinueButton = styled.div`
  color: ${Colors.Black};
  background-color: ${Colors.Green};  
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
`
