import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import styled from "styled-components";

export default function YouFound(props) {

  console.log(props.item);

  const onClickOK = () => {
    props.sendUpdate();
  }

  return (
    <div style={{ width: "100%", textAlign: "center", marginTop: "180px" }}>
      <div style={{ color: Colors.Green, fontSize: Sizes.Medium }}>
        You found:
      </div>
      <div style={{ color: Colors.Green }}>
        {props.item}
      </div>
      <div style={{ color: Colors.Green, fontSize: Sizes.Large, marginTop: Sizes.Small, marginBottom: Sizes.Small }}>
        GOOD JOB!
      </div>
      <div style={{ color: Colors.Green }}>
        Click OK to continue exploring.
      </div>
      <OKButton onClick={onClickOK}>OK</OKButton>
    </div>
  )
}

const OKButton = styled.div`
  color: ${Colors.Green}; 
  border: 2px solid ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: 60px auto;
`