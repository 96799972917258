import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css"
import ProtectedPage from './components/ProtectedPage';
import PublicPage from './components/UnprotectedPage';
import Login from './pages/Login';
import Register from './pages/Register';
import Overworld from './pages/Overworld';
import Alley from './pages/Alley';
import Hideout from './pages/Hideout';
import ThriftShop from './pages/ThriftShop';
import Locker from './pages/Locker';
import City from './pages/City';
import ParkingLot from './pages/ParkingLot';
import Garage from './pages/Garage';
import Sewers from './pages/Sewers';
import ThePharmacist from './pages/ThePharmacist';
import SomebodysBackyard from './pages/SomebodysBackyard';
import Downtown from './pages/Downtown';
import Tower from './pages/Tower';
import ShoppingMall from './pages/ShoppingMall';
import Thunderdome from './pages/Thunderdome';
import Road from './pages/Road';
import Wasteland from './pages/Wasteland';
import Online from './pages/Online';
import Mirror from './pages/Mirror';
import PineBarrens from './pages/PineBarrens';
import Cabin from './pages/Cabin';
import Desert from './pages/Desert';
import Area51 from './pages/Area51';
import Pyramid from './pages/Pyramid';
import Shuttle from './pages/Shuttle';
import Space from './pages/Space';
import PsychicPlane from './pages/PsychicPlane';
import Meteorite from './pages/Meteorite';
import Moon from './pages/Moon';
import DemonEncampment from './pages/DemonEncampment';
import BlackTetradhedron from './pages/BlackTetrahedron';
import DraculasMoonBase from './pages/DraculasMoonBase';
import MysteriousDigSite from './pages/MysteriousDigSite';
import Library from './pages/Library';

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* PUBLIC PAGES */}
          <Route path='/register' element={<PublicPage><Register/></PublicPage>} />
          <Route path='/login' element={<PublicPage><Login/></PublicPage>} />

          {/* HIDDEN PAGES */}

          {/** PERSONAL ROUTES */}
          <Route path='/hideout' element={<ProtectedPage><Hideout/></ProtectedPage>} />
          <Route path='/locker' element={<ProtectedPage><Locker/></ProtectedPage>} />
          <Route path="/online" element={<ProtectedPage><Online/></ProtectedPage>} />
          <Route path="/mirror" element={<ProtectedPage><Mirror/></ProtectedPage>} />

          {/** OVERWORLD ROUTES */}
          <Route path='/' element={<ProtectedPage><Overworld/></ProtectedPage>} />
          <Route path='/alley' element={<ProtectedPage><Alley/></ProtectedPage>} />
          <Route path='/road' element={<ProtectedPage><Road/></ProtectedPage>} />
          <Route path='/thrift-shop' element={<ProtectedPage><ThriftShop/></ProtectedPage>} />
          <Route path='/backyard' element={<ProtectedPage><SomebodysBackyard/></ProtectedPage>} />

          {/** CITY ROUTES */}
          <Route path='/city' element={<ProtectedPage><City/></ProtectedPage>} />
          <Route path='/garage' element={<ProtectedPage><Garage/></ProtectedPage>} />
          <Route path='/sewers' element={<ProtectedPage><Sewers/></ProtectedPage>} />
          <Route path='/parking-lot' element={<ProtectedPage><ParkingLot/></ProtectedPage>} />
          <Route path='/the-pharmacist' element={<ProtectedPage><ThePharmacist/></ProtectedPage>} />

          {/** DOWNTOWN ROUTES */}
          <Route path='/downtown' element={<ProtectedPage><Downtown/></ProtectedPage>} />
          <Route path='/tower' element={<ProtectedPage><Tower/></ProtectedPage>} />
          <Route path='/thunderdome' element={<ProtectedPage><Thunderdome/></ProtectedPage>} />
          <Route path="/shopping-mall" element={<ProtectedPage><ShoppingMall/></ProtectedPage>}/>

          {/** WASTELAND ROUTES */}
          <Route path="/wasteland" element={<ProtectedPage><Wasteland/></ProtectedPage>} />
          <Route path="/pine-barrens" element={<ProtectedPage><PineBarrens/></ProtectedPage>} />
          <Route path="/cabin" element={<ProtectedPage><Cabin/></ProtectedPage>} />

          {/** DESERT ROUTES */}
          <Route path="/desert" element={<ProtectedPage><Desert/></ProtectedPage>} />
          <Route path="/area51" element={<ProtectedPage><Area51/></ProtectedPage>} />
          <Route path="/pyramid" element={<ProtectedPage><Pyramid/></ProtectedPage>} />
          <Route path="/shuttle" element={<ProtectedPage><Shuttle/></ProtectedPage>} />
          <Route path="/library" element={<ProtectedPage><Library/></ProtectedPage>} />

          {/** PSYCHIC PLANE ROUTES */}
          <Route path="/psychic-plane" element={<ProtectedPage><PsychicPlane/></ProtectedPage>} />
          <Route path="/meteorite" element={<ProtectedPage><Meteorite/></ProtectedPage>} />
          <Route path="/demon-encampment" element={<ProtectedPage><DemonEncampment/></ProtectedPage>} />
          <Route path="/tetrahedron" element={<ProtectedPage><BlackTetradhedron/></ProtectedPage>} />

          {/** SPACE ROUTES */}
          <Route path="/space" element={<ProtectedPage><Space/></ProtectedPage>} />
          <Route path="/moon" element={<ProtectedPage><Moon/></ProtectedPage>} />
          <Route path="/dig-site" element={<ProtectedPage><MysteriousDigSite/></ProtectedPage>} />
          <Route path="/draculas-moon-base" element={<ProtectedPage><DraculasMoonBase/></ProtectedPage>} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
