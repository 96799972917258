import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import mechanic from "../assets/npcs/mechanic3.png";
import SpeechText from "../components/SpeechText";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquare } from "@fortawesome/free-regular-svg-icons";
import useIsMobile from "../hooks/useIsMobile";

export default function Garage() {
  const user = useSelector((state) => { return state.user});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const questStarted = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "motorcycle";
  const [showMotorcycle, setShowMotorcycle] = useState(false);

  const hasPart1 = user.userInfo.activeQuest && user.userInfo.activeQuest.part1;
  const hasPart2 = user.userInfo.activeQuest && user.userInfo.activeQuest.part2;
  const hasPart3 = user.userInfo.activeQuest && user.userInfo.activeQuest.part3;

  const questCompleted = hasPart1 && hasPart2 && hasPart3;
  const questFinished = user.userInfo.completedQuests.includes("motorcycle");

  const mechanicDialogue = {
    conArtistDialogue: {
      intro: "Hey friend. If you can find the parts I need, my old motorbike is all yours.",
      secondary: "My old motorbike. If you can find the parts I need, she's yours.",
      final: "Holy hell... this motorcycle seems like its got a mind of its own! Be careful with this."
    },
    regularDialogue: {
      intro: "A motorbike eh? I've got an old chassis, but you'll have to bring me some parts if you want it up and running.",
      secondary: "Here's what I need: a new wheel, an engine, and a demon's soul. For you know, uh, fuel.",
      final: "This is one crazy bike! You'd better take it for a spin."
    }
  }

  const activeTextSource = mechanicDialogue.regularDialogue;
  const [text, setText] = useState(activeTextSource.intro);

  useEffect(() => {
    if (questFinished) {
      setShowMotorcycle(false);
      setText(activeTextSource.final);
    } else if (questStarted) {
      setShowMotorcycle(true);
      setText(activeTextSource.secondary);
    }
  }, [questStarted, questFinished]);

  const onClickBack = () => {
    navigate("/city");
  }

  const onClickOK = () => {
    setText(activeTextSource.secondary);
    setShowMotorcycle(true);

    const startLolaQuest = async () => {
      const startNewQuestResponse = await api.users.startNewQuest({
        name: "motorcycle",
        part1: false,  // tire
        part2: false,  // engine
        part3: false   // soul
      });

      if (!startNewQuestResponse || !startNewQuestResponse.data) {
        console.log("Error in startNewQuest()")
      }

      if (startNewQuestResponse && startNewQuestResponse.data) {
        console.log("Data: ", startNewQuestResponse.data);
        dispatch(setUserInfo(startNewQuestResponse.data));
      }
    }
    startLolaQuest().catch(err => console.error(err));
  }

  const onClickFinish = () => {
    setText(activeTextSource.final);

    const completeQuest = async () => {
      const completeQuestResponse = await api.users.completeQuest("motorcycle");
  
      if (!completeQuestResponse || !completeQuestResponse.data) {
        console.log("Error in completeQuest()")
      }
  
      if (completeQuestResponse && completeQuestResponse.data) {
        console.log("Data: ", completeQuestResponse.data);
        dispatch(setUserInfo(completeQuestResponse.data));
      }
    }

    completeQuest().catch(err => console.error(err));
  }

  return (
    <>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div className="page-title">
        Auto Repair
      </div>
      <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
        <div style={{ width: "300px", padding: "20px 20px" }}>
          <img style={{ width: "100%" }} src={mechanic} alt="mechanic"/>
          <div style={{ color: Colors.Green, height: "24px" }}><SpeechText text={text}/></div>
          {!questStarted && !questFinished &&
            <OKButton onClick={onClickOK}>
              OK
            </OKButton>
          }
        </div>
        <SlideInFromRight $visible={showMotorcycle}>
          <h1 style={{ color: Colors.Green, width: "100%", textAlign: "center" }}>MOTORCYCLE</h1>
          <div>
            <ChecklistItem>
              <FontAwesomeIcon icon={hasPart1 ? faSquareCheck : faSquare}></FontAwesomeIcon>
              <ChecklistItemText>Tire</ChecklistItemText>
            </ChecklistItem>
            <ChecklistItem>
              <FontAwesomeIcon icon={hasPart2 ? faSquareCheck : faSquare}></FontAwesomeIcon>
              <ChecklistItemText>Engine</ChecklistItemText>
            </ChecklistItem>
            <ChecklistItem>
              <FontAwesomeIcon icon={hasPart3 ? faSquareCheck : faSquare}></FontAwesomeIcon>
              <ChecklistItemText>Soul</ChecklistItemText>
            </ChecklistItem>
          </div>
          {questCompleted &&
            <FinishButton onClick={onClickFinish}>FINISH!</FinishButton>
          }
        </SlideInFromRight>
      </div>
    </>
  )
}

const OKButton = styled.div`
  color: ${Colors.Green}; 
  border: 2px solid ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: 60px auto;
`

const SlideInFromRight = styled.div`
  transform: ${props => (props.$visible ? 'translateX(0)' : 'translateX(10%)')};
  opacity: ${props => props.$visible ? 1 : 0};
  transition: transform 0.2s ease-in-out, opacity 0.2s linear;
  width: 350px;
`;

const ChecklistItem = styled.div`
  margin: auto;
  color: ${Colors.Green};
  padding: 0px 4px 0px 24px;
  size: ${Sizes.Medium}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ChecklistItemText = styled.div`
  padding-left: 4px;
  size: 18px;
`

const FinishButton = styled.div`
  color: ${Colors.black}; 
  margin: 24px auto auto auto; 
  width: fit-content; 
  padding: ${Sizes.ExtraSmall}px ${Sizes.Small}px; 
  background-color: ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
`