import styled from "styled-components";
import Colors from "../constants/Colors";

const IconContainer = styled.div`
  position: absolute;
  top: ${props => props.$top}px;
  left: ${props => props.$left}px;
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0));
  cursor: pointer;
  transition: filter .4s;

  .hover-text {
    opacity: 0;
    -webkit-text-stroke: 0.5px ${Colors.White};
    text-stroke: 0.5px ${Colors.White};
    font-weight: 700;
    transition: opacity .4s;
  }

  &:hover {
    filter: drop-shadow(0px 0px 6px ${Colors.White});

    .hover-text {
      opacity: 1;
    }
  }
`

export default IconContainer;