import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import LevelNotHighEnough from "../components/LevelNotHighEnough";
import IconContainer from "../components/IconContainer";
import tetrahedron from "../assets/locations/tetrahedron.png";
import meteorite from "../assets/locations/meteorite3.png";
import demon_camp from "../assets/locations/demon_camp.png";
import purple_portal from "../assets/locations/purple_portal2.png";
import PsychicCanvas from "../components/PsychicCanvas";
import useIsMobile from "../hooks/useIsMobile";

export default function PsychicPlane() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userLevel = user.userInfo.level;
  const reqLevel = 25;

  const onClickReturnToWasteland = () => {
    navigate("/wasteland");
  }

  const onClickRadioactiveMeteorite = () => {
    navigate("/meteorite");
  }

  const onClickDemonEncampment = () => {
    navigate("/demon-encampment");
  }

  const onClickBlackTetrahedron = () => {
    navigate("/tetrahedron");
  }

  if (userLevel < reqLevel) {
    return <LevelNotHighEnough level={reqLevel} returnLocation="/wasteland"/>
  }

  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll" } : {position: "relative", height: Sizes.MainHeight }}>
        <PsychicCanvas width={694} height={550} style={{ position: "absolute", top: 0 }}/>
        <IconContainer $top={98} $left={482} onClick={onClickReturnToWasteland}>
          <img className="inverted-color" alt="portal" style={{ height: "auto", width: "93px" }} src={purple_portal}></img>
          <div className="hover-text">Back to Wasteland</div>
        </IconContainer> 
        <IconContainer $top={130} $left={159} onClick={onClickBlackTetrahedron}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={tetrahedron}></img>
          <div className="hover-text">Black Tetrahedron</div>
        </IconContainer>
        <IconContainer $top={330} $left={390} onClick={onClickRadioactiveMeteorite}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={meteorite}></img>
          <div className="hover-text">Radioactive Meteorite</div>
        </IconContainer>
        <IconContainer $top={370} $left={78} onClick={onClickDemonEncampment}>
          <img alt="back" style={{ height: "auto", width: "144px" }} src={demon_camp}></img>
          <div className="hover-text">Demon Encampment</div>
        </IconContainer>
      </div>
    </>
  )
}