import Colors from "../constants/Colors";
import { useSelector } from "react-redux";
import Sizes from "../constants/Sizes";
import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
import { useState } from "react";
import styled from "styled-components";
import { retrievePlayersVisitedLocations } from "../utilities/helpers";
import HoverItem from "../components/HoverItem";
import sleeping_bag from "../assets/locations/sleeping_bag.png";
import locker from "../assets/locations/locker.png";
import online from "../assets/locations/online.png";
import mirror from "../assets/locations/mirror.png";
import transit_map from "../assets/locations/transit_map.png";
import IconContainer from "../components/IconContainer";
import useIsMobile from "../hooks/useIsMobile";

export default function Hideout(props) {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [hasSlept, setHasSlept] = useState(false);
  const [showRapidTransitModal, setShowRapidTransitModal] = useState(false);
  const visitedLocations = retrievePlayersVisitedLocations(user.userInfo);
  const [hpToRestore, setHpToRestore] = useState(0);
  const [playerDead, setPlayerDead] = useState(user.userInfo.hp <= 0);
  const [sanityLossText, setSanityLossText] = useState("");
  const [sleepText, setSleepText] = useState("You get a decent nights sleep.");

  const onClickOnline = () => {
    navigate("/online");
  }

  const onClickLocker = () => {
    navigate("/locker")
  }

  const onClickRapidTransit = () => {
    setShowRapidTransitModal(true);
  }

  const onClickMirror = () => {
    navigate("/mirror")
  }

  const onClickSleepingBag = () => {
    setHpToRestore(user.userInfo.maxHp - user.userInfo.hp);
    let sanityUpdate = user.userInfo.sanity;
    if (playerDead) {
      sanityUpdate -= 1;
      setSleepText("You thrash about and have horrible nightmares.")
      setSanityLossText(", -1 sanity");
    }

    const restoreUserToMaxHp = async () => {
      var updateUserStatsResponse = await api.users.updateUserStats(user.userInfo._id, {
        hp: user.userInfo.maxHp,
        sanity: sanityUpdate
      });

      if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
        console.log("Something went wrong.")
      }

      if (updateUserStatsResponse && updateUserStatsResponse.data) {
        console.log(updateUserStatsResponse);
        setHasSlept(true);
        props.setNeedsUpdate(true);
        setTimeout(() => {
          setPlayerDead(false);
          setHpToRestore(0);
          setHasSlept(false);
          setSanityLossText("");
          setSleepText("You get a decent nights sleep.");
        }, 2000);
      }
    }

    restoreUserToMaxHp().catch(err => console.error(err));
  }

  const RapidTransitModal = (props) => {
    const onClickClose = () => {
      props.onClose();
    }

    const onClickOverworld = () => {
      navigate("/");
    }

    const onClickCity = () => {
      navigate("/city");
    }

    const onClickDowntown = () => {
      navigate("/downtown");
    }

    const onClickWasteland = () => {
      navigate("/wasteland");
    }

    const onClickDesert = () => {
      navigate("/desert");
    }

    const onClickThePsychicPlane = () => {
      navigate("/psychic-plane");
    }

    const onClickTheMoon = () => {
      navigate("/moon");
    }

    return (
      <div style={{ color: Colors.Green, height: 320, width: 300, border: `1px solid ${Colors.Green}`, position: "absolute", top: 25, left: 200, backgroundColor: Colors.Black }}>
        <div onClick={onClickClose} style={{ color: Colors.Black, backgroundColor: Colors.Green, width: "fit-content", padding: "1px 4px", margin: "2px", cursor: "pointer", position: "absolute", right: 0 }}>X</div>
        <div style={{ marginTop: Sizes.Large, textAlign: "center" }}>You pull out your crumpled transit map.</div>
        <div style={{ marginTop: Sizes.Small }}>
          <HoverItem onClick={onClickOverworld} text="Overworld"/>
          {visitedLocations.includes("city") &&
            <HoverItem onClick={onClickCity} text="City"/>
          }
          {visitedLocations.includes("downtown") &&
            <HoverItem onClick={onClickDowntown} text="Downtown"/>
          }
          {visitedLocations.includes("wasteland") &&
            <HoverItem onClick={onClickWasteland} text="Wasteland"/>
          }
          {visitedLocations.includes("desert") &&
            <HoverItem onClick={onClickDesert} text="Desert"/>
          }
          {visitedLocations.includes("psychicplane") &&
            <HoverItem onClick={onClickThePsychicPlane} text="The Psychic Plane"/>
          }
          {visitedLocations.includes("moon") &&
            <HoverItem onClick={onClickTheMoon} text="The Moon"/>
          }
        </div>
      </div>
    )
  }

  return (
    <div style={isMobile ? { height: Sizes.MainHeight, position: "relative", overflowX: "scroll" } : { height: Sizes.MainHeight, position: "relative" }}> 
      <div style={{ color: Colors.Green, fontSize: Sizes.Large, textAlign: "center", padding: Sizes.Small, fontWeight: 700 }}>
        {user.userInfo.username}'s Hideout
      </div>
      <div>
        <IconContainer $top={315} $left={403} onClick={onClickSleepingBag}>
          <img alt="sleeping bag" style={{ height: "auto", width: "178px" }} src={sleeping_bag}></img>
          <div className="hover-text">Sleeping Bag</div>
        </IconContainer>
        <IconContainer $top={310} $left={70} onClick={onClickLocker}>
          <img alt="locker" style={{ height: "auto", width: "96px" }} src={locker}></img>
          <div className="hover-text">Locker</div>
        </IconContainer>
        <IconContainer $top={130} $left={96} onClick={onClickMirror}>
          <img alt="mirror" style={{ height: "auto", width: "96px" }} src={mirror}></img>
          <div className="hover-text">Mirror</div>
        </IconContainer>
        <IconContainer $top={163} $left={310} onClick={onClickOnline}>
          <img alt="online" style={{ height: "auto", width: "96px" }} src={online}></img>
          <div className="hover-text">Go Online</div>
        </IconContainer>
        <IconContainer $top={99} $left={465} onClick={onClickRapidTransit}>
          <img alt="transit" style={{ height: "auto", width: "82px" }} src={transit_map}></img>
          <div className="hover-text">Rapid Transit</div>
        </IconContainer>
      </div>
      <SlideInFromBottom $visible={hasSlept} className="decent-sleep">
        {hasSlept &&
          <div className="decent-sleep-text">
            <div>{sleepText}</div>
            <div>(+{hpToRestore} HP{sanityLossText})</div>
          </div>
        }
      </SlideInFromBottom>  
      <SlideInFromBottom $visible={showRapidTransitModal}>
        {showRapidTransitModal &&
          <RapidTransitModal userInfo={user.userInfo} onClose={() => setShowRapidTransitModal(false)}/>
        }
      </SlideInFromBottom>  
    </div>
  )
}

const SlideInFromBottom = styled.div`
  transform: ${props => (props.$visible ? 'translateY(0)' : 'translateY(25px)')};
  opacity: ${props => props.$visible ? 1 : 0};
  transition: transform 0.2s ease-in-out, opacity 0.2s linear;

  .decent-sleep-text {
    position: absolute; 
    top: 420px;
    width: ${Sizes.MainWidth};
    color: ${Colors.Green}; 
    font-size: ${Sizes.Medium}px; 
    text-align: center; 
  }
`;