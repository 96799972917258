import React, { useEffect, useRef } from 'react';

const GrassyPlainCanvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = props.width;
      canvas.height = props.height;
      drawGrass(ctx);
    };

    resizeCanvas();
  }, []);

  const drawGrass = (ctx) => {
    const grassColors = ['#2E8B57', '#3CB371', '#32CD32']; // Different shades of green
    const numGrassBlades = 500;

    for (let i = 0; i < numGrassBlades; i++) {
      const x = Math.random() * canvasRef.current.width;
      const y = Math.random() * canvasRef.current.height * 0.9 + canvasRef.current.height * 0.1; // Random Y position with more focus towards bottom
      const height = Math.random() * 15 + 10; // Random height for grass blades
      const thickness = Math.random() * 3 + 1; // Random thickness for grass blades
      const color = grassColors[Math.floor(Math.random() * grassColors.length)]; // Random color

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, y - height);
      ctx.strokeStyle = color;
      ctx.lineWidth = thickness;
      ctx.lineCap = 'round';
      ctx.stroke();
    }
  };

  return <canvas ref={canvasRef} style={{ backgroundColor: "#A9A877" }} />;
};

export default GrassyPlainCanvas;