import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import LevelNotHighEnough from "../components/LevelNotHighEnough";
import IconContainer from "../components/IconContainer";
import NightSky from "../components/NightSkyCanvas";
import CityCanvas from "../components/CityCanvas";
import brainsystower from "../assets/locations/brainsystower.png";
import thunderdome from "../assets/locations/thunderdome.png";
import back from "../assets/locations/back.png";
import mall from "../assets/locations/mall.png";
import useIsMobile from "../hooks/useIsMobile";

export default function Downtown() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userLevel = user.userInfo.level;
  const reqLevel = 10;

  const onClickReturnToCity = () => {
    navigate("/city");
  }

  const onClickThunderDome = () => {
    navigate("/thunderdome");
  }

  const onClickBrainSysTower = () => {
    navigate("/tower");
  }

  const onClickShoppingMall = () => {
    navigate("/shopping-mall");
  }

  if (userLevel < reqLevel) {
    return <LevelNotHighEnough level={reqLevel} returnLocation="/city"/>
  }

  return (
    <>

      {/*
        Arcade
        TODO:
      */}
      <div style={isMobile ? { position: "relative", overflowX: "scroll", height: Sizes.MainHeight } : {position: "relative"}}>
        <NightSky width={694} height={250} style={{ position: "absolute", top: 0 }}/>
        <div style={{ position: "absolute", top: 120 }}>
          <CityCanvas width={694} height={430} />
        </div>
        <IconContainer $top={330} $left={20} onClick={onClickReturnToCity}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={back}></img>
          <div className="hover-text">Return to City</div>
        </IconContainer>
        <IconContainer $top={30} $left={520} onClick={onClickBrainSysTower}>
          <img alt="go downtown" style={{ height: "auto", width: "82px" }} src={brainsystower}></img>
          <div className="hover-text">Brain-Sys Tower</div>
        </IconContainer>
        <IconContainer $top={353} $left={280} onClick={onClickThunderDome}>
          <img alt="go downtown" style={{ height: "auto", width: "192px" }} src={thunderdome}></img>
          <div className="hover-text">Thunderdome</div>
        </IconContainer>
        <IconContainer $top={150} $left={150} onClick={onClickShoppingMall}>
          <img alt="go downtown" style={{ height: "auto", width: "96px" }} src={mall}></img>
          <div className="hover-text">Shopping Mall</div>
        </IconContainer>
      </div>
    </>
  )
}