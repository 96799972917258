import { useEffect, useState } from "react";

export default function SpeechText(props) {
  // let string = "Hey hot stuff. You needs a discount or what?";
  let delay = 10;
  const [textToSpeak, setTextToSpeak] = useState(props.text);
  const [speakingText, setSpeakingText] = useState("");
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    setTextIndex(0);
    setSpeakingText("");
    setTextToSpeak(props.text);
  }, [props.text]);

  useEffect(() => {
    if (textIndex < textToSpeak.length) {

      const timeout = setTimeout(() => {
        setSpeakingText(prevText => prevText + textToSpeak[textIndex]);
        setTextIndex(prevIndex => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [delay, speakingText, textIndex, textToSpeak]);

  return (
    <span>{speakingText}</span>
  )
}