import React, { useEffect, useRef } from 'react';

const NightSky = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let stars = [];

    // Function to initialize stars
    const initStars = (numStars) => {
      for (let i = 0; i < numStars; i++) {
        stars.push({
          x: Math.random() * width,
          y: Math.random() * height,
          radius: Math.random() * 1.5 + 0.5, // Random radius between 0.5 and 2
          opacity: Math.random() * 0.5 + 0.5, // Random opacity between 0.5 and 1
          speed: Math.random() * 0.5 + 0.1, // Random speed between 0.1 and 0.6
        });
      }
    };

    // Function to draw stars
    const drawStars = () => {
      ctx.clearRect(0, 0, width, height);
      stars.forEach(star => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`;
        ctx.fill();
      });
    };

    // Function to update stars
    const updateStars = () => {
      stars.forEach(star => {
        star.x += star.speed;
        if (star.x > width) {
          star.x = 0;
        }
      });
    };

    // Animation loop
    const animate = () => {
      drawStars();
      updateStars();
      requestAnimationFrame(animate);
    };

    // Initialize stars and start animation when the component mounts
    initStars(100); // Adjust the number of stars as needed
    animate();

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      stars = [];
    };
  }, [width, height]);

  return <canvas ref={canvasRef} width={width} height={height} style={{ backgroundColor: 'black' }} />;
};

export default NightSky;