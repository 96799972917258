import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false,
    token: "",
    userInfo: {
      hp: 0,
      maxHp: 0,
      activeQuest: {
        name: ""
      }
    }
  },
  reducers: {
    setLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setUserHp(state, action) {
      state.userInfo.hp = action.payload;
    }
  }
})

export const { setLoggedIn, setToken, setUserInfo, setUserHp } = userSlice.actions;

export default userSlice.reducer;