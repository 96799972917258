import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PageLayout from "./PageLayout";

export default function PublicPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => { return state.user});

  useEffect(() => {
    if (loggedIn) {
      navigate('/');
    }
  }, [loggedIn, navigate, dispatch]);

  return (
    <PageLayout>
      {props.children}
    </PageLayout>
  )
}