import { useDispatch } from "react-redux";
import { api } from "../axios/api";
import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { setLoggedIn } from "../store/user";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import book from "../assets/logo/book.png";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputOpacity, setInputOpacity] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [loginInfo, setLoginInfo] = useState({
    username: "",
    password: ""
  });
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    setInputOpacity(1);
    const opacityTimeout = setTimeout(() => {
      setOpacity(1);
    }, 750);

    return () => {
      clearInterval(opacityTimeout);
    }
  }, []);

  const onChangeInput = (e) => {
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value
    })
  }
  
  const onClickLogin = () => {
    setLoginError("");

    const login = async () => {
      const loginResponse = await api.auth.login({
        username: loginInfo.username,
        password: loginInfo.password
      });

      if (!loginResponse || !loginResponse.data) {
        console.error("Something went wrong with login.");
      }

      if (loginResponse && loginResponse.data && loginResponse.data.token) {
        dispatch(setLoggedIn(true));
        localStorage.setItem("nanoToken", loginResponse.data.token);
      }
    }
    login().catch(err => {
      console.error(err)
      setLoginError(err.response.data.message);
    });
  }

  const onClickRegister = () => {
    navigate("/register")
  }

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClickLogin();
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
      {/* <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg" fill={Colors.Green} fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11z"/></svg>       */}
      <div style={{ display: "flex", flexDirection: "column", padding: "10px", alignItems: "center" }}>
        <div style={{ position: "relative", height: "200px", width: "192px" }}>
          <BookContainer $opacity={inputOpacity}>
            <img src={book} width="192px"></img>
          </BookContainer>
        </div>
        <FadingInputWrapper $inputopacity={inputOpacity}>
          <div style={{ color: Colors.Green, fontSize: "16px" }}>Username</div>
          <FadingInput 
            type="text" 
            onChange={onChangeInput}
            onKeyDown={onPressEnter}
            id="username" 
            name="username" 
            value={loginInfo.username}
          />
        </FadingInputWrapper>
        <div style={{ height: 10 }}></div>
        <FadingInputWrapper $inputopacity={inputOpacity}>
          <div style={{ color: Colors.Green, fontSize: "16px" }}>Password</div>
          <FadingInput 
            type="password"
            onChange={onChangeInput}
            onKeyDown={onPressEnter}
            id="password" 
            name="password" 
            value={loginInfo.password}
          />
        </FadingInputWrapper>
      </div>
      {loginError.length > 0 &&
        <div style={{ color: Colors.Red, marginTop: "10px", marginBottom: "10px" }}>{loginError}</div>
      }
      <div style={{ color: Colors.Green, width: "fit-content", margin: "auto" }}>
        <div style={{ height: Sizes.Medium }}></div>
        <HoverOuterItem opacity={opacity}>
          {"["}
          <FadingButton opacity={opacity} onClick={onClickLogin}>Login</FadingButton>
          {"]"}
        </HoverOuterItem>
        <div style={{ height: Sizes.Medium }}></div>
        <HoverOuterItem opacity={opacity}>
          {"["}
          <FadingButton opacity={opacity} onClick={onClickRegister}>Register</FadingButton>
          {"]"}
        </HoverOuterItem>
      </div>
    </div>
  )
}

const FadingInputWrapper = styled.div`
  maxWidth: 200px;
  text-align: left;
  font-size: ${Sizes.Large}px;
  opacity: ${props => props.$inputopacity};
  transition-duration: 1000ms;
`

const FadingInput = styled.input`
  padding: ${Sizes.Small}px; 
  color: ${Colors.Green}; 
  font-size: ${Sizes.Large}px;
  background-color: ${Colors.Black}; 
  border-color: ${Colors.Green}; 
  border-radius: ${Sizes.Small}px; 
  border-style: solid;
`

const FadingButton = styled.div`
  font-size: ${Sizes.Large}px;
  opacity: ${props => props.opacity};
  transition-duration: 1000ms;
  cursor: pointer;
  color: ${Colors.Green};
  padding: 0px 8px;
`

const HoverOuterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${props => props.opacity};
  transition: color 0.2s ease-in-out, opacity 0.1s ease-in-out;
  color: ${Colors.Black};
  margin: auto;
  width: fit-content;
  padding: 4px 8px;
  cursor: pointer;
  font-size: ${Sizes.Large}px;

  &:hover {
    color: ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`

const BookContainer = styled.div`
  position: absolute;
  opacity: ${props => props.$opacity};
  transition-duration: 1000ms;
`