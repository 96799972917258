import React, { useEffect, useRef } from 'react';

const SpaceCanvas = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const stars = [];
    const speed = 3;

    function generateStar() {
        const star = {
            x: canvas.width / 2,
            y: canvas.height / 2,
            radius: Math.random() * 2,
            vx: Math.random() * speed * 2 - speed,
            vy: Math.random() * speed * 2 - speed
        };
        stars.push(star);
    }

    function draw() {
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        for (let i = 0; i < stars.length; i++) {
            const star = stars[i];

            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fillStyle = 'white';
            ctx.fill();

            star.x += star.vx;
            star.y += star.vy;

            if (star.x < 0 || star.x > canvas.width || star.y < 0 || star.y > canvas.height) {
                stars.splice(i, 1); // Remove star if it moves out of the canvas
                generateStar(); // Generate a new star
            }
        }

        requestAnimationFrame(draw);
    }

    // Generate initial stars
    for (let i = 0; i < 200; i++) {
        generateStar();
        generateStar();
        generateStar();
        generateStar();

    }

    draw();
  }, [width, height]);

  return <canvas ref={canvasRef} width={width} height={height} style={{ backgroundColor: 'black' }} />;
};

export default SpaceCanvas;