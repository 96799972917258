import { useNavigate } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import SandCanvas from "../components/SandCanvas";
import NightSky from "../components/NightSkyCanvas";
import desert from "../assets/locations/desert.png";
import road from "../assets/locations/road.png";
import pine_barrens from "../assets/locations/pine_barrens.png";
import cabin from "../assets/locations/cabin2.png";
import purple_portal from "../assets/locations/purple_portal2.png";
import IconContainer from "../components/IconContainer";

export default function Wasteland() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onClickReturnToOverworld = () => {
    navigate("/");
  }

  const onClickPineBarrens = () => {
    navigate("/pine-barrens");
  }

  const onClickLogCabin = () => {
    navigate("/cabin");
  }

  const onClickDesert = () => {
    navigate("/desert");
  }

  const onClickPortal = () => {
    navigate("/psychic-plane");
  }

  
  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll" } : {position: "relative"}}>
        <NightSky width={694} height={250} style={{ position: "relative", overflow: "auto" }}/>
        <SandCanvas width={694} height={296}/>
        <IconContainer $top={254} $left={320} onClick={onClickDesert}>
          <img alt="go downtown" style={{ height: "auto", width: "96px" }} src={desert}></img>
          <div className="hover-text">Desert</div>
        </IconContainer>
        <IconContainer $top={290} $left={598} onClick={onClickReturnToOverworld}>
          <img alt="road" style={{ height: "auto", width: "96px", transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }} src={road}></img>
          <div className="hover-text">Overworld</div>
        </IconContainer>
        <IconContainer $top={357} $left={128} onClick={onClickPineBarrens}>
          <img alt="road" style={{ height: "auto", width: "192px", transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }} src={pine_barrens}></img>
          <div className="hover-text">Pine Barrens</div>
        </IconContainer> 
        <IconContainer $top={320} $left={405} onClick={onClickLogCabin}>
          <img alt="road" style={{ height: "auto", width: "96px" }} src={cabin}></img>
          <div className="hover-text">Log Cabin</div>
        </IconContainer> 
        <IconContainer $top={195} $left={25} onClick={onClickPortal}>
          <img alt="portal" style={{ height: "auto", width: "93px" }} src={purple_portal}></img>
          <div className="hover-text">Portal</div>
        </IconContainer> 
      </div>
    </>
  )
}