import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RoadCanvas from "../components/RoadCanvas";
import useIsMobile from "../hooks/useIsMobile";

export default function Road(props) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const user = useSelector((state) => { return state.user});
  const hasMotorcycle = user.userInfo.completedQuests.includes("motorcycle");

  const onClickReturn = () => {
    navigate("/");
  }

  const onClickContinue = () => {
    navigate("/wasteland");
  }

  return (
    <>
      {!hasMotorcycle &&
        <div style={{ width: "100%", textAlign: "center", marginTop: "180px" }}>
          <div style={{ color: Colors.Green, marginTop: Sizes.Small }}>
            The road to the wasteland is long and treacherous. 
          </div>
          <div style={{ color: Colors.Green, marginTop: Sizes.Small, marginBottom: "60px" }}>
            You will need a vehicle to continue.
          </div>
          <ReturnButton onClick={() => onClickReturn()}>RETURN</ReturnButton> 
        </div>
      }
      {hasMotorcycle &&
        <div style={{ width: "100%", height: "370px", textAlign: "center", marginTop: "180px" }}>
          <div style={{ color: Colors.Green, marginTop: Sizes.Small }}>
            Your motorcycle screams across the highway like a bat out of hell!
          </div>
          <div style={{ color: Colors.Green, marginTop: Sizes.Small, marginBottom: "60px" }}>
            You ride along the wasteland road at incredible speed.
          </div>
          <ReturnButton onClick={() => onClickContinue()}>CONTINUE</ReturnButton> 
          <div style={{ position: "relative", marginTop: "86px" }}>
            <RoadCanvas width={isMobile ? 400 : 694} height={120}  style={{ position: "absolute", top: 100 }}/>
          </div>
        </div>
        // <div style={{ position: "relative", height: "370px" }}>
        //   <RoadCanvas width={isMobile ? 400 : 694} height={100}  style={{ position: "absolute" }}/>
        //   <div style={{ backgroundColor: Colors.Black, position: "absolute", textAlign: "center", top: 120, left: isMobile ? 80 : 120, width: 370, padding: 50, borderRadius: 200 }}>
        //     <div style={{ color: Colors.Green, marginTop: Sizes.Small }}>
        //       Your motorcycle screams across the highway like a bat out of hell!
        //     </div>
        //     <div style={{ color: Colors.Green, marginTop: Sizes.Small, marginBottom: "60px" }}>
        //       You ride along the wasteland road at incredible speed.
        //     </div>
        //     <ReturnButton onClick={() => onClickContinue()}>CONTINUE</ReturnButton> 
        //   </div>
        // </div>
      }
    </>
  )
}

const ReturnButton = styled.div`
  color: ${Colors.Black};
  background-color: ${Colors.Green};  
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
`