import { createSlice } from "@reduxjs/toolkit";

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    currentMonster: null,
    currentLocation: "",
    playerHasInitiative: false,
    activeBattle: false,
    turnsOfBonusDmg: 0,
    isInBattleground: false
  },
  reducers: {
    setCurrentMonster(state, action) {
      state.currentMonster = action.payload;
    },
    setCurrentLocation(state, action) {
      state.currentLocation = action.payload;
    },
    setPlayerHasInitiative(state, action) {
      state.playerHasInitiative = action.payload;
    },
    decreaseTurnsOfBonusDmg(state) {
      if (state.turnsOfBonusDmg > 0) {
        state.turnsOfBonusDmg -= 1;
      }
    },
    gainFiveTurnsOfBonusDmg(state) {
      state.turnsOfBonusDmg = state.turnsOfBonusDmg + 5;
    },
    setActiveBattle(state, action) {
      state.activeBattle = action.payload;
    },
    setIsInBattleground(state, action) {
      state.isInBattleground = action.payload;
    }
  }
})

export const { setCurrentMonster, setCurrentLocation, setPlayerHasInitiative, setActiveBattle, decreaseTurnsOfBonusDmg, gainFiveTurnsOfBonusDmg, setIsInBattleground } = gameSlice.actions;

export default gameSlice.reducer;