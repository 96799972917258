import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NightSky from "../components/NightSkyCanvas";
import GrassyPlainCanvas from "../components/GrassyPlainCanvas";
import IconContainer from "../components/IconContainer";
import Alley from "../assets/locations/alley.png";
import someonesbackyard from "../assets/locations/someonesbackyard.png"
import value_vamp from "../assets/locations/value_vamp.png";
import road from "../assets/locations/road.png";
import city from "../assets/locations/city.png";
import useIsMobile from "../hooks/useIsMobile";
import Sizes from "../constants/Sizes";

export default function Overworld() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onClickAlley = () => {
    navigate("/alley")
  }

  const onClickThriftShop = () => {
    navigate("/thrift-shop")
  }

  const onClickCity = () => {
    navigate("/city")
  }

  const onClickRoad = () => {
    navigate("/road")
  }

  const onClickSomebodysBackyard = () => {
    navigate("/backyard")
  }

  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll" } : { position: "relative", height: Sizes.MainHeight }}>
        <IconContainer $top={300} $left={500} onClick={onClickAlley}>
          <img alt="alley" style={{ height: "auto", width: "96px" }} src={Alley}></img>
          <div className="hover-text">Alley</div>
        </IconContainer>
        <IconContainer $top={360} $left={350} onClick={onClickThriftShop}>
          <img alt="vamp" style={{ height: "auto", width: "96px" }} src={value_vamp}></img>
          <div className="hover-text">Thrift Shop</div>
        </IconContainer>      
        <IconContainer $top={300} $left={170} onClick={onClickSomebodysBackyard}>
          <img alt="backyard" style={{ height: "auto", width: "96px" }} src={someonesbackyard}></img>
          <div className="hover-text">Someone's Backyard</div>
        </IconContainer>      
        <IconContainer $top={290} $left={0} onClick={onClickRoad}>
          <img alt="road" style={{ height: "auto", width: "96px" }} src={road}></img>
          <div className="hover-text">Road to the Wasteland</div>
        </IconContainer> 
        <IconContainer $top={160} $left={400} onClick={onClickCity}>
          <img alt="city" style={{ height: "auto", width: "182px" }} src={city}></img>
          <div className="hover-text">The City</div>
        </IconContainer> 
        <NightSky width={694} height={250} style={{ position: "relative", overflow: "auto" }}/>
        <GrassyPlainCanvas width={694} height={296}/>
      </div>
    </>
  )
}