import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function LevelNotHighEnough(props) {
  const navigate = useNavigate();

  const onClickReturn = () => {
    navigate(props.returnLocation);
  }

  return (
    <div style={{ width: "100%", textAlign: "center", marginTop: "180px" }}>
      <div style={{ color: Colors.Green, fontSize: Sizes.Large }}>
        HALT, TRAVELER!
      </div>
      <div style={{ color: Colors.Green, marginTop: Sizes.Small }}>
        You are too weak to continue.
      </div>
      <div style={{ color: Colors.Green, marginBottom: Sizes.ExtraLarge }}>
        You must first reach level {props.level} before continuing.
      </div>
      <ReturnButton onClick={onClickReturn}>RETURN</ReturnButton>
    </div>
  )
}


const ReturnButton = styled.div`
  color: ${Colors.Black};
  background-color: ${Colors.Green};  
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
`