import { useDispatch } from "react-redux";
import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { setLoggedIn, setUserInfo } from "../store/user";
import { faPersonWalkingArrowRight, faTentArrowDownToLine, faEarthAmericas, faComputer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { api } from "../axios/api";
import CharacterCreation from "./CharacterCreation";
import LevelUp from "./LevelUp";
import { useNavigate } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import useIsMobile from "../hooks/useIsMobile";
import { setCurrentMonster, setIsInBattleground } from "../store/game";

export default function PageLayout(props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const user = useSelector((state) => { return state.user});
  const game = useSelector((state) => { return state.game });
  const navigate = useNavigate();
  const needsCharCreation = user.userInfo.level === 0 && props.protected;
  const isInBattleground = game.isInBattleground;

  // can only level up if user is not in the battleground.
  const needsLevelUp = (user.userInfo.exp >= user.userInfo.expNeeded && props.protected && !isInBattleground);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const yellowHealth = user.userInfo.hp <= (user.userInfo.maxHp / 2);
  const redHealth = user.userInfo.hp <= (user.userInfo.maxHp / 4);
  const healthColor = redHealth ? Colors.Red : yellowHealth ? Colors.Gold : Colors.Green;

  const mainHeight = Sizes.MainHeight;

  const yellowSanity = user.userInfo.sanity <= 50;
  const redSanity = user.userInfo.sanity <= 25;
  const sanityColor = redSanity ? Colors.Red : yellowSanity ? Colors.Gold : Colors.Green;

  useEffect(() => { 
    const getMe = async () => {
      var getMeResponse = await api.users.getMe();

      if (!getMeResponse || !getMeResponse.data) {
        return;
      }

      if (getMeResponse && getMeResponse.data) {
        dispatch(setUserInfo(getMeResponse.data));
      }
    }

    getMe().catch(err => console.error(err));

    if (needsUpdate) {
      setNeedsUpdate(false);
    }
  }, [needsUpdate, dispatch]);

  const onClickLogout = () => {
    // disallow user from improper fleeing in fights
    if (game.currentMonster) {
      return;
    }
    dispatch(setLoggedIn(false));
  }

  const onClickMap = () => {
    // disallow user from improper fleeing in fights
    if (game.activeBattle) {
      return;
    }

    if (game.currentMonster) {
      dispatch(setCurrentMonster(null))
    }

    dispatch(setIsInBattleground(false));

    navigate("/");
  }

  const onClickHideout = () => {
    // disallow user from improper fleeing in fights
    if (game.activeBattle) {
      return;
    }

    if (game.currentMonster) {
      dispatch(setCurrentMonster(null))
    }

    dispatch(setIsInBattleground(false));

    navigate("/hideout");
  }

  const onClickOnline = () => {
    // disallow user from improper fleeing in fights
    if (game.activeBattle) {
      return;
    }

    if (game.currentMonster) {
      dispatch(setCurrentMonster(null))
    }

    dispatch(setIsInBattleground(false));

    navigate("/online");
  }

  const MapButton = () => {
    return (
      <div onClick={onClickMap} style={{ cursor: "pointer", padding: Sizes.Small }}>
        <div style={{ margin: "auto", width: "fit-content" }}>
          <FontAwesomeIcon icon={faEarthAmericas} color={Colors.Green} size="2x"/>
        </div>
        <div style={{ fontSize: Sizes.Small, textAlign: "center", paddingTop: 2 }}>Back to Map</div>
      </div>
    )
  }

  const HideoutButton = () => {
    return (
      <div onClick={onClickHideout} style={{ cursor: "pointer", padding: Sizes.Small }}>
        <div style={{ margin: "auto", width: "fit-content" }}>
          <FontAwesomeIcon icon={faTentArrowDownToLine} color={Colors.Green} size="2x"/>
        </div>
        <div style={{ fontSize: Sizes.Small, textAlign: "center", paddingTop: 2 }}>Go to Hideout</div>
      </div>
    )
  }

  const OnlineButton = () => {
    return (
      <div onClick={onClickOnline} style={{ cursor: "pointer", padding: Sizes.Small }}>
        <FontAwesomeIcon icon={faComputer} color={Colors.Green} size="2x"/>
        <div style={{ fontSize: Sizes.Small, textAlign: "center", paddingTop: 2 }}>Online</div>
      </div>
    )
  }

  const LogoutButton = () => {
    return (
      <div onClick={onClickLogout} style={{ cursor: "pointer", padding: Sizes.Small }}>
        <FontAwesomeIcon icon={faPersonWalkingArrowRight} color={Colors.Green} size="2x"/>
        <div style={{ fontSize: Sizes.Small, textAlign: "center", paddingTop: 2 }}>Logout</div>
      </div>
    )
  }

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "40px" }}/>
      <div style={{ maxWidth: Sizes.MainWidth, minHeight: mainHeight, margin: "auto" }}>
        {(!needsCharCreation && props.protected) &&
          <StatsBar $protected={props.protected}>
            <div>
              <Stat>LVL: {user.userInfo.level} {`(${user.userInfo.exp}/${user.userInfo.expNeeded})`}</Stat>
              |
              <Stat>HP: <span style={{ color: healthColor }}>{user.userInfo.hp}</span>/{user.userInfo.maxHp}</Stat>
              |
              <Stat>STR: {user.userInfo.strength}</Stat>
              |
              <Stat>DEX: {user.userInfo.dexterity}</Stat>
              |
              <Stat>MIN: {user.userInfo.mind}</Stat>
              |
              <Stat>END: {user.userInfo.endurance}</Stat>
              |
              <Stat><span style={{ color: Colors.Gold }}>{user.userInfo.gold} G</span></Stat>
            </div>
            <div>
              Sanity: <b style={{ color: sanityColor }}>{user.userInfo.sanity}</b>
            </div>
          </StatsBar>
        }
        <MainContainer $protected={props.protected} $mainheight={mainHeight}>
          {needsCharCreation ? (
            <CharacterCreation userId={user.userInfo._id} />
          ) : needsLevelUp ? (
            <LevelUp userInfo={user.userInfo} userId={user.userInfo._id}/>
          ) : (
            React.Children.map(props.children, child => {
              // check if child is valid element
              if (React.isValidElement(child)) {
                return React.cloneElement(child, { needsUpdate: needsUpdate, setNeedsUpdate: setNeedsUpdate });
              }
              return child;
            })
          )}
        </MainContainer>
        {props.protected &&
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: Colors.Green, marginTop: isMobile ? 35 : 0 }}>
            {/* <div>This is the nav tool.</div> */}
            <MapButton />
            {!needsCharCreation && <HideoutButton />}
            {!needsCharCreation && <OnlineButton />}
            <LogoutButton />
          </div>
        }
      </div>
    </div>
  )
}

const Stat = styled.span`
  color: ${Colors.White};
  font-weight: 500;
  padding: 0px 4px;
`

const MainContainer = styled.div`
  min-height: ${props => props.$mainheight}; 
  border-width: 3px; 
  border-style: double; 
  border-color: ${props => props.$protected ? Colors.Green : Colors.Black};
`

const StatsBar = styled.div`
  color: ${Colors.Green}; 
  padding: ${Sizes.ExtraSmall}px; 
  border-top-width: 3px; 
  border-left-width: 3px; 
  border-right-width: 3px; 
  border-right-style: double; 
  border-top-style: double; 
  border-left-style: double; 
  border-color: ${props => props.$protected ? Colors.Green : Colors.Black}; 
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
`