import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const RoadCanvas = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Square properties
    const squareSize = 50;
    const squareColor = 'yellow';
    const speed = 10;

    let squares = [];

    const middlePos = width / 2;

    // Function to create a square object
    function createSquare(x, y) {
      return {
          x: x,
          y: y,
          draw: function() {
              ctx.fillStyle = squareColor;
              ctx.fillRect(middlePos, this.y, 10, squareSize);
          },
          update: function() {
              this.y += speed;
              if (this.y > canvas.height) {
                  this.y = -squareSize;
              }
          }
      };
    }

    // Function to initialize squares
    function init() {
        const numberOfSquares = 1;
        for (let i = 0; i < numberOfSquares; i++) {
            squares.push(createSquare(i * squareSize, -squareSize));
        }
    }

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      squares.forEach(square => {
        square.update();
        square.draw();
      });
    };

    // Function to draw a triangle
    function drawTriangle() {
      ctx.fillStyle = 'blue';
      ctx.beginPath();
      ctx.moveTo(canvas.width / 2, 20);
      ctx.lineTo(canvas.width / 2 - 20, 20);
      ctx.lineTo(canvas.width / 2 + 20, 20);
      ctx.closePath();
      ctx.fill();
    }

    // Initialize stars and start animation when the component mounts
    init();
    animate();

    drawTriangle();

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      squares = [];
    };
  }, [width, height]);

  return (
    <div>
      <svg style={{ position: "absolute" }} width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <polygon points="0,0 100,0 0,100" fill="rgb(183, 142, 99)" />
      </svg>
      <canvas ref={canvasRef} width={width} height={height} style={{ backgroundColor: 'black' }} />;
      <svg style={{ position: "absolute", right: 0, top: 0 }} width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <polygon points="0,0 100,0 100,100" fill="rgb(183, 142, 99)" />
      </svg>
    </div>
  )

};

export default RoadCanvas;