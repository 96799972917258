import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import LevelNotHighEnough from "../components/LevelNotHighEnough";
import CityCanvas from "../components/CityCanvas"
import NightSky from "../components/NightSkyCanvas";
import IconContainer from "../components/IconContainer";
import mechanic from "../assets/locations/mechanic.png";
import parking_lot from "../assets/locations/parking_lot.png";
import pharmacist from "../assets/locations/pharmacist.png";
import back from "../assets/locations/back.png";
import sewers from "../assets/locations/sewers.png";
import downtown from "../assets/locations/downtown.png";
import useIsMobile from "../hooks/useIsMobile";

export default function City() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userLevel = user.userInfo.level;
  const reqLevel = 5;

  const onClickReturnToOverworld = () => {
    navigate("/");
  }

  const onClickGarage = () => {
    navigate("/garage");
  }

  const onClickSewers = () => {
    navigate("/sewers");
  }

  const onClickParkingLot = () => {
    navigate("/parking-lot")
  }

  const onClickThePharmacist = () => {
    navigate("/the-pharmacist");
  }

  const onClickDowntown = () => {
    navigate("/downtown")
  }

  if (userLevel < reqLevel) {
    return <LevelNotHighEnough level={reqLevel} returnLocation="/"/>
  }

  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll", height: Sizes.MainHeight } : {position: "relative"}}>
        <NightSky width={694} height={250} style={{ position: "absolute", top: 0 }}/>
        <div style={{ position: "absolute", top: 120 }}>
          <CityCanvas width={694} height={430} />
        </div>
        <IconContainer $top={330} $left={20} onClick={onClickReturnToOverworld}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={back}></img>
          <div className="hover-text">Return to Overworld</div>
        </IconContainer>
        <IconContainer $top={260} $left={325} onClick={onClickGarage}>
          <img alt="mechanic" style={{ height: "auto", width: "96px" }} src={mechanic}></img>
          <div className="hover-text">Mechanic</div>
        </IconContainer>
        <IconContainer $top={360} $left={480} onClick={onClickParkingLot}>
          <img alt="parking lot" style={{ height: "auto", width: "96px" }} src={parking_lot}></img>
          <div className="hover-text">Abandoned Parking Lot</div>
        </IconContainer>
        <IconContainer $top={256} $left={150} onClick={onClickThePharmacist}>
          <img alt="the pharmacist" style={{ height: "auto", width: "96px" }} src={pharmacist}></img>
          <div className="hover-text">The Pharmacist</div>
        </IconContainer>
        <IconContainer $top={400} $left={196} onClick={onClickSewers}>
          <img alt="the pharmacist" style={{ height: "auto", width: "96px" }} src={sewers}></img>
          <div className="hover-text">The Sewers</div>
        </IconContainer>
        <IconContainer $top={240} $left={520} onClick={onClickDowntown}>
          <img alt="go downtown" style={{ height: "auto", width: "96px" }} src={downtown}></img>
          <div className="hover-text">Downtown</div>
        </IconContainer>
      </div>
    </>
  )
}