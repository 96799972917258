import { useNavigate } from "react-router-dom";
import SandCanvas from "../components/SandCanvas";
import NightSky from "../components/NightSkyCanvas";
import { useSelector } from "react-redux";
import LevelNotHighEnough from "../components/LevelNotHighEnough";
import IconContainer from "../components/IconContainer";
import pyramid from "../assets/locations/pyramid.png"
import shuttle from "../assets/locations/shuttle.png"
import area51 from "../assets/locations/area51.png"
import back from "../assets/locations/back.png"
import library from "../assets/locations/library.png"
import useIsMobile from "../hooks/useIsMobile";

export default function Desert() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const userLevel = user.userInfo.level;
  const reqLevel = 20;

  const onClickReturnToWasteland = () => {
    navigate("/wasteland");
  }

  const onClickArea51 = () => {
    navigate("/area51");
  }

  const onClickPyramid = () => {
    navigate("/pyramid");
  }

  const onClickShuttle = () => {
    navigate("/shuttle");
  }

  const onClickLibrary = () => {
    navigate("/library");
  }

  if (userLevel < reqLevel) {
    return <LevelNotHighEnough level={reqLevel} returnLocation="/wasteland"/>
  }
  
  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll" } : {position: "relative"}}>
        <NightSky width={694} height={250} style={{ position: "relative", overflow: "auto" }}/>
        <SandCanvas width={694} height={296}/>
        <IconContainer $top={158} $left={108} onClick={onClickArea51}>
          <img alt="area 51" style={{ height: "auto", width: "144px" }} src={area51}></img>
          <div className="hover-text">Area 51</div>
        </IconContainer>
        <IconContainer $top={185} $left={438} onClick={onClickPyramid}>
          <img alt="pyramid" style={{ height: "auto", width: "144px" }} src={pyramid}></img>
          <div className="hover-text">Pyramid</div>
        </IconContainer>
        <IconContainer $top={345} $left={478} onClick={onClickShuttle}>
          <img alt="shuttle" style={{ height: "auto", width: "96px" }} src={shuttle}></img>
          <div className="hover-text">Wrecked Space Shuttle</div>
        </IconContainer>
        <IconContainer $top={345} $left={258} onClick={onClickLibrary}>
          <img alt="library" style={{ height: "auto", width: "96px" }} src={library}></img>
          <div className="hover-text">Ancient Library</div>
        </IconContainer>
        <IconContainer $top={335} $left={40} onClick={onClickReturnToWasteland}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={back}></img>
          <div className="hover-text">Back to Wasteland</div>
        </IconContainer>
      </div>
    </>
  )
}