import Battleground from "../components/Battleground"
import { useDispatch, useSelector } from "react-redux";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";
import YouFound from "../components/YouFound";

export default function Alley() {
  const user = useSelector((state) => { return state.user });
  const dispatch = useDispatch();
  const requiresMotorcycleTire = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "motorcycle" && !user.userInfo.activeQuest.part1;

  const onReceiveUpdate = () => {
    const receiveUpdate = async () => {
      const updatePart1Response = await api.users.progressQuest("part1");
  
      if (updatePart1Response && updatePart1Response.data) {
        dispatch(setUserInfo(updatePart1Response.data));
      } 
    }

    receiveUpdate().catch(err => console.error(err));
  }

  if (requiresMotorcycleTire) {
    return <YouFound item={"An old motorcycle tire!"} sendUpdate={onReceiveUpdate}/>
  }
  
  return (
    <Battleground location={"alley"} returnLocation={"/"}/>
  )
}