export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
}

export const getRandomIntMinMax = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const generateAttackResults = (weapon, user, monster) => {
  const attributeBonus = user.userInfo[weapon.relatedAttribute];
  // players with neurotic trait get extra critical chance when sanity is below or equal to 50
  const hasNeuroticEffect = user.userInfo.trait.toLowerCase() === "neurotic" && user.userInfo.sanity <= 50;
  const hasDesertGoggles = user.userInfo.loadOut.head && user.userInfo.loadOut.head.id === 5; // goggles
  const hasTacticalVisor = user.userInfo.loadOut.head && user.userInfo.loadOut.head.id === 6; // visor
  const hasCombatBoots = user.userInfo.loadOut.feet && user.userInfo.loadOut.feet.id === 25;  // combat boots
  const hasKevlarVest = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 14;  // kevlar vest
  const hasTemplarArmor = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 20;  // templar armor
  const hasBadassTrenchcoat = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 15; // badass trenchcoat
  const hasAntiGravityBoots = user.userInfo.loadOut.feet && user.userInfo.loadOut.feet.id === 27; // anti-gravity boots

  // rasputin's fit halves all incoming damage
  const fullLoadOut = user.userInfo.loadOut.head && user.userInfo.loadOut.body && user.userInfo.loadOut.feet;
  const rasputinComplete = fullLoadOut && user.userInfo.loadOut.head.id === 9 && user.userInfo.loadOut.body.id === 19 && user.userInfo.loadOut.feet.id === 22;

  // for these three items, you must also MATCH the required attribute.
  const hasNinjaGarbEffect = weapon.relatedAttribute === "dexterity" && user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 16; // ninja garb
  const hasPoweredExoSkeletonEffect = weapon.relatedAttribute === "strength" && user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 17; // powered exoskeleton
  const hasTatteredLabCoatEffect = weapon.relatedAttribute === "mind" && user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 18; // tattered lab coat

  let enemyChanceToHit = getRandomInt(100) + 1;
  let enemyHit = false;
  let playerChanceToHit = getRandomInt(100) + 1;
  let playerHit = false;
  let playerCritical = false;

  // players with high strength have lower critical hit threshholds, equal to their strength score.
  let bonusCriticalChance = user.userInfo.strength;
  let playerCriticalHitThreshhold = 95 - bonusCriticalChance;

  // players with neurotic have their critical hit threshhold lowered by 15%.
  if (hasNeuroticEffect) {
    playerCriticalHitThreshhold -= 15;
  }

  // players wearing the tactical visor gain 5% chance to hit 
    if (hasDesertGoggles) {
    playerChanceToHit += 5;
  }

  // players wearing anti-gravity boots gain 10% evasion
  if (hasAntiGravityBoots) {
    enemyChanceToHit -= 10;
  }

  // players wearing the tactical visor gain 10% chance to hit 
  // they also have their critical hit threshhold lowered by 5%.
  if (hasTacticalVisor) {
    playerChanceToHit += 10;
    playerCriticalHitThreshhold -= 5;
  }

  // player defense is 20 + 1/2 of their dexterity score.
  let bonusDefense = user.userInfo.dexterity;
  let playerDefense = 20 + bonusDefense;

  // see if enemy lands a hit
  if (enemyChanceToHit >= playerDefense) {
    enemyHit = true;
  }

  // see if player makes a critical.
  if (playerChanceToHit >= playerCriticalHitThreshhold) {
    playerCritical = true;
  }

  // see if player lands a hit.
  // player chance is a d100 rolled, plus the attribute bonus.  
  let playerModChanceToHit = playerChanceToHit + attributeBonus;
  if (playerModChanceToHit >= 20) {
    playerHit = true;
  }

  // player's damage calculation and damage text generation
  let index = getRandomInt(weapon.damageText.length);
  let chosenDamageText = weapon.damageText[index];

  // critical hit text
  if (playerCritical) {
    chosenDamageText = "Critical Hit! " + chosenDamageText;
  }

  let playerDmg = 0;
  let playerRolls = weapon.baseDmg.rolls;
  // critical hits get 1.5x the damage dice
  // if the player is under the effects of Neurotic, they get 2x the damage dice for a critical.
  let hasNeuroticCritical = playerCritical && hasNeuroticEffect;
  let playerMaxRolls = 
    hasNeuroticCritical ? playerRolls * 2 : 
    playerCritical ? playerRolls + Math.floor(playerRolls / 2) : 
    playerRolls;

  for (let i = 0; i < playerMaxRolls; i++) {
    playerDmg += (getRandomInt(weapon.baseDmg.d) + 1);
  }

  if (hasBadassTrenchcoat) {
    playerDmg += 2;
  }

  // all these items have the same effect, deal 5 extra damage.
  if (hasPoweredExoSkeletonEffect || hasNinjaGarbEffect || hasTatteredLabCoatEffect) {
    playerDmg += 5;
  }

  let totalDamage = playerDmg + attributeBonus;
  let finalDamageText = chosenDamageText + totalDamage + " damage!";

  // enemy monster's damage calculation and text gen
  let monsterIndex = getRandomInt(monster.attackText.length);
  let chosenMonsterText = monster.attackText[monsterIndex];

  let monsterDmg = 0;
  for (let j = 0; j < monster.attackDmg.rolls; j++) {
    monsterDmg += (getRandomInt(monster.attackDmg.d) + 1);
  }

  // combat boots reduces incoming damage by 2. dmg cannot be negative
  // kevlar vest reduces incoming damage by 2. dmg cannot be negative.
  // templar armor reduces incoming damage by 5. dmg cannot be negative.
  let damageReductionFromClothes = 0;
  if (hasCombatBoots) {
    damageReductionFromClothes += 2;
  }
  if (hasKevlarVest) {
    damageReductionFromClothes += 2;
  }
  if (hasTemplarArmor) {
    damageReductionFromClothes += 5;
  }

  if (damageReductionFromClothes > 0) {
    monsterDmg = (monsterDmg - damageReductionFromClothes >= 0) ? (monsterDmg - damageReductionFromClothes) : 0;
  }

  if (rasputinComplete) {
    monsterDmg = Math.ceil(monsterDmg / 2);
  }

  let finalMonsterText = chosenMonsterText + monsterDmg + " damage!";

  if (!playerHit) {
    finalDamageText = "You miss!!";
    totalDamage = 0;
  }

  if (!enemyHit) {
    finalMonsterText = "Their attack misses!!";
    monsterDmg = 0;
  }

  return {
    playerText: finalDamageText,
    playerWeaponDamage: totalDamage,
    monsterText: finalMonsterText,
    monsterDamage: monsterDmg
  }
} 

export const findIfPlayerHasInitiative = (user) => {
  const hasRunningShoes = user.userInfo.loadOut.feet && user.userInfo.loadOut.feet.id === 23; // running shoes
  let enemyInitiative = getRandomInt(20);
  let playerRoll = getRandomInt(20);

  const playerBonus = user.userInfo.dexterity;
  // get a plus 2 to your initiative roll if you have running shoes.
  const shoesBonus = hasRunningShoes ? 2 : 0;
  let playerInitiative = playerRoll + playerBonus + shoesBonus;

  let doesPlayerHaveInit = playerInitiative >= enemyInitiative;
  return doesPlayerHaveInit;
}

// return true if player passes sanity check
export const playerSanityCheck = (user, sanityCheck) => {
  // test whole numbers
  // let mindBonus = user.userInfo.mind;
  let mindBonus = Math.floor(user.userInfo.mind / 2);
  let sanityRoll = getRandomInt(20) + 1 + mindBonus;

  console.log(sanityRoll >= sanityCheck);

  return sanityRoll >= sanityCheck;
}

export const retrievePlayersVisitedLocations = (userInfo) => {
  let visitedLocations = ["overworld"]
  
  if (userInfo.level >= 5) {
    visitedLocations.push("city");
  }

  if (userInfo.level >= 10) {
    visitedLocations.push("downtown");
  }

  if (userInfo.completedQuests.includes("motorcycle")) {
    visitedLocations.push("wasteland");
  }

  if (userInfo.level >= 20) {
    visitedLocations.push("desert");
  }

  if (userInfo.level >= 25) {
    visitedLocations.push("psychicplane");
  }

  if (userInfo.completedQuests.includes("shuttle")) {
    visitedLocations.push("moon");
  }

  return visitedLocations;
}