import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useSelector } from "react-redux";
import pharmacist from "../assets/npcs/pharmacist.png";
import SpeechText from "../components/SpeechText";
import Traits from "../constants/Traits";
import { api } from "../axios/api";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/user";
import { useNavigate } from "react-router-dom";
import { gainFiveTurnsOfBonusDmg } from "../store/game";
import styled from "styled-components";
import useIsMobile from "../hooks/useIsMobile";

export default function ThePharmacist(props) {
  const user = useSelector((state) => { return state.user});
  const game = useSelector((state) => { return state.game});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const isConArtist = user.userInfo.trait === Traits.ConArtist;
  const isResearcher = user.userInfo.trait === Traits.Researcher;
  const lowSanity = user.userInfo.sanity <= 50;
  const userInsane = user.userInfo.sanity <= 0;
  let availableGold = user.userInfo.gold;
  const cost = isConArtist ? (user.userInfo.pharmacyCost * 0.9) : user.userInfo.pharmacyCost;
  const hasLitCigaretteEquipped = user.userInfo.loadOut.head && user.userInfo.loadOut.head.id === 1;
  const hasDiaperEquipped = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 12;

  const sanityLost = hasDiaperEquipped ? 3 : 5;
  const sanityToBeGained = isResearcher ? 15 : 10;

  const shopKeepDialogue = {
    conArtistDialogue: {
      intro: `${lowSanity ? "The pharmacist seems very concerned for your well-being." : "The pharmacist looks pleased to see you."}`,
      success: "The pharmacist's joyous needle soothes your gentle soul. You've regained some sanity.",
      hallucinogen: "You're hopped up on that good shit. You'll deal an additional +2 damage for five additional rounds of combat, but a piece of your sanity has withered away.",
      failure: "The pharmacist needs money for their services."
    },
    regularDialogue: {
      intro: `${lowSanity ? "The pharmacist is weirded out. You look like a lunatic." : "The pharmacist welcomes you into their office."}`,
      success: "The pharmacist urges you to drink a bottle of some medicine. You feel a little bit more sane.",
      hallucinogen: "You see nightmares beyond your comprehension. You also vomit for hours. A part of your sanity has withered away...  but you've also gained a +2 bonus to damage, which will last for five additional rounds of combat.",
      failure: "The pharmacist looks like they're going to beat your ass if you don't come back with some money."
    }
  }


  const activeTextSource = isConArtist ? shopKeepDialogue.conArtistDialogue : shopKeepDialogue.regularDialogue;
  const [text, setText] = useState(activeTextSource.intro);

  const onClickBack = () => {
    navigate("/city");
  }

  const postNews = async () => {
    const message = `${user.userInfo.username} stole some pills!!`;
    const postNewsResponse = await api.news.postNews(message);
    
    if (!postNewsResponse || !postNewsResponse.data) {
      console.log("Something went wrong in postNews()");
    }
  }

  // reduce the user's sanity and preserve their gold
  // or 
  // save user's new sanity and less gold
  const updateUserStatsAfterAction = async (action) => {
    let payloadForUpdateUserStats = {};
    if (action === "buy") {
      let sanityGained = isResearcher ? 15 : 10;
      if (hasLitCigaretteEquipped) {
        sanityGained += 2;
      }
      let newSanity = user.userInfo.sanity + sanityGained;
      let newGold = availableGold - cost;

      let newPharmacyCost = user.userInfo.pharmacyCost + 500;

      payloadForUpdateUserStats = {
        sanity: newSanity,
        gold: newGold,
        pharmacyCost: newPharmacyCost
      }
    } else if (action === "steal") {
      let newSanity = user.userInfo.sanity - sanityLost;
      
      payloadForUpdateUserStats = {
        sanity: newSanity
      }
    } else {
      console.log("Must specify an action");
      return;
    }


    var updateUserStatsResponse = await api.users.updateUserStats(user.userInfo._id, payloadForUpdateUserStats);

    if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
      console.log("Something went wrong.")
    }

    if (updateUserStatsResponse && updateUserStatsResponse.data) {
      console.log(updateUserStatsResponse);
      dispatch(setUserInfo(updateUserStatsResponse.data));
    }
  }

  const onClickBuy = () => {
    if (availableGold < cost) {
      setText(activeTextSource.failure);
      return;
    } else {
      setText(activeTextSource.success);
      updateUserStatsAfterAction("buy").catch(err => console.error(err));
      props.setNeedsUpdate(true);
    }
  }

  const onClickSteal = () => {
    setText(activeTextSource.hallucinogen);
    updateUserStatsAfterAction("steal").catch(err => console.error(err));
    postNews().catch(err => console.error(err));
    dispatch(gainFiveTurnsOfBonusDmg());
    props.setNeedsUpdate(true);
  }


  return (
    <div>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div className="page-title">
        The Pharmacy
      </div>
      <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
        <div style={{ width: "300px", padding: "20px 20px", margin: "auto" }}>
          <img style={{ width: "100%" }} src={pharmacist} alt="pharmacist"/>
          <div style={{ color: Colors.Green, height: "24px" }}><SpeechText text={text}/></div>
        </div>
        <div style={{ width: "300px", margin: "auto", marginTop: Sizes.ExtraLarge }}>
          <HoverOuterItem onClick={() => onClickBuy()}>
            {"["}<HoverInnerItem>1. Pay them for their expertise, gain {sanityToBeGained} sanity. ({cost}G)</HoverInnerItem>{"]"}
          </HoverOuterItem>
          {!userInsane &&
            <HoverOuterItem onClick={() => onClickSteal()}>
              {"["}<HoverInnerItem>2. Steal a random pill and swallow it immediately.</HoverInnerItem>{"]"}
            </HoverOuterItem>
          }
        </div> 
      </div>

    </div>
  )
}


const HoverInnerItem = styled.div`
  color: ${Colors.Green};
  padding: 8px 8px;
  width: 360px;
  font-size: 20px;
  font-weight: 400;
`

const HoverOuterItem = styled.div`
  display: flex;
  flex-direction: row;
  transition: color 0.2s ease-in-out;
  color: ${Colors.Black};
  margin: auto;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;

  &:hover {
    color: ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`