import { instance } from "./axios";

export const api = {
  auth: {
    login: async ({ username, password }) => {
      return await instance.post("/auth/login", {
        username,
        password
      })
    },
    register: async ({ username, password }) => {
      return await instance.post("/auth/register", {
        username,
        password
      })
    }
  },
  users: {
    getAllUsers: async () => await instance.get("/users"),
    getMe: async () => await instance.get("/users/me"),
    updateUserStats: async (id, userStats) => await instance.patch("/users/stats/" + id, {
      userStats
    }),
    updateUserWeaponInventory: async (item) => await instance.post("/users/inventory/weapon", {
      item
    }),
    updateUserAfterBattle: async (id, userStats) => await instance.patch("/users/update_after_battle/" + id, {
      userStats
    }),
    updateUserLoadout: async (itemId, equipSlot) => await instance.post("/users/loadout", {
      itemId,
      equipSlot
    }),
    startNewQuest: async (quest) => await instance.post("/users/new_quest", {
      quest
    }),
    completeQuest: async (questName) => await instance.post("/users/complete_quest", {
      questName
    }),
    progressQuest: async (part) => await instance.post("/users/progress_quest", {
      partToUpdate: part
    }),
    levelUp: async (id, userStats) => await instance.patch("/users/level_up/" + id, {
      userStats
    })
  },
  items: {
    getAllAvailableWeapons: async () => await instance.get("/items"),
    getAvailableClothesByRarity: async (rarity) => await instance.post("/clothes", {
      rarity
    })
  },
  monster: {
    getAllMonsters: async () => await instance.get("/monsters"),
    getMonstersForLocation: async (location) => await instance.post("/monstersForLocation", {
      location
    }),
    getMonsterAndLoot: async (location) => await instance.post("/monsterAndLoot", {
      location
    })
  },
  messages: {
    postMessage: async (text) => await instance.post("/messages", {
      text
    }),
    getAllMessages: async () => await instance.get("/messages")
  },
  news: {
    postNews: async (text) => await instance.post("/news", {
      text
    }),
    getAllNews: async () => await instance.get("/news")
  }
}