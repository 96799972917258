import Colors from "../constants/Colors";
import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGun, faShirt, faSocks,faFaceDizzy, faRotate } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";
import useIsMobile from "../hooks/useIsMobile";

export default function Locker() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const userWeapons = user.userInfo.weaponInventory;
  const userClothing = user.userInfo.clothingInventory;

  const [selectedEquipmentType, setSelectedEquipmentType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [showDiv, setShowDiv] = useState(false);
  const renderWeaponList = (selectedEquipmentType === "Weapon 1" || selectedEquipmentType === "Weapon 2");
  const renderClothingList = (selectedEquipmentType === "Head" || selectedEquipmentType === "Body" || selectedEquipmentType === "Feet")
  const headItemText = user.userInfo.loadOut.head ? user.userInfo.loadOut.head.name : "NONE"
  const bodyItemText = user.userInfo.loadOut.body ? user.userInfo.loadOut.body.name : "NONE"
  const feetItemText = user.userInfo.loadOut.feet ? user.userInfo.loadOut.feet.name : "NONE";
  const weapon1text = user.userInfo.loadOut.weapon1 ? user.userInfo.loadOut.weapon1.name : "NONE";
  const weapon2text = user.userInfo.loadOut.weapon2 ? user.userInfo.loadOut.weapon2.name : "NONE";

  const fullLoadOut = user.userInfo.loadOut.head && user.userInfo.loadOut.body && user.userInfo.loadOut.feet;
  const rasputinComplete = fullLoadOut && user.userInfo.loadOut.head.id === 9 && user.userInfo.loadOut.body.id === 19 && user.userInfo.loadOut.feet.id === 22;
  const [clothingDisplayDescription, setClothingDisplayDescription] = useState("");

  useEffect(() => {
    // Trigger the animation when the component mounts
    setShowDiv(true);
    setOpacity(1);
  }, []);

  useEffect(() => {
    if (selectedItem) {
      if (rasputinComplete && (selectedItem.id === 22 || selectedItem.id === 9 || selectedItem.id ===19)) {
        setClothingDisplayDescription("EFFECT: Halve all incoming damage.")
      } else if (selectedItem.description) {
        setClothingDisplayDescription(selectedItem.description);
      }
    }
  }, [selectedItem, rasputinComplete]);

  const onClickBack = () => {
    navigate('/hideout');
  }

  const onClickEquipmentSlot = (type) => {
    let parsedSlotName = type.toLowerCase().split(' ').join('');

    setSelectedItem(user.userInfo.loadOut[parsedSlotName]);

    if (type !== selectedEquipmentType) {
      setShowDiv(false);
      setOpacity(0);

      setTimeout(() => {
        setShowDiv(true);
        setOpacity(1);
        setSelectedEquipmentType(type);
      }, 200);
    }
  }

  const onClickEquip = (item) => {    
    let itemId = item.id;
    let parsedSlotName = selectedEquipmentType.toLowerCase().split(' ').join('');
  
    const equipItem = async () => {
      var equipItemResponse = await api.users.updateUserLoadout(itemId, parsedSlotName);

      if (!equipItemResponse || !equipItemResponse.data) {
        console.log("Something went wrong with equipItem().");
        return;
      }

      if (equipItemResponse && equipItemResponse.data) {
        dispatch(setUserInfo(equipItemResponse.data));
      }
    }
    equipItem().catch(err => console.error(err));
  }

  const WeaponWithStats = () => {
    if (selectedItem && selectedItem.baseDmg) {
      const { name, flavorText, relatedAttribute } = selectedItem;
      // const attributeBonus = Math.floor(user.userInfo[relatedAttribute] / 2);
      // test using whole number
      const attributeBonus = user.userInfo[relatedAttribute];
      let damageText = `DMG: ${selectedItem.baseDmg.rolls + attributeBonus} - ${(selectedItem.baseDmg.d * selectedItem.baseDmg.rolls) + attributeBonus}`;
      let relatedAttributeText = `Related Attribute: ${relatedAttribute}`

      return (
        <div className="opacity-item">
          <div style={{ color: Colors.Green, padding: Sizes.Small, textAlign: "center", fontSize: 20 }}>{name}</div>
          <div style={{ color: Colors.Green, paddingBottom: Sizes.Large, textAlign: "center", fontStyle: "italic", fontSize: 12, width: "80%", margin: "auto" }}>{flavorText}</div>
          <div style={{ color: Colors.Green, textAlign: "center", fontSize: 14 }}>{relatedAttributeText}</div>
          <div style={{ color: Colors.Green, textAlign: "center", fontSize: 20, marginBottom: Sizes.Large }}>{damageText}</div>
          <EquipButton
            onClick={() => onClickEquip(selectedItem)}
          >
            EQUIP!
          </EquipButton>
        </div>
      )
    } else {
      return null;
    }
  }

  const ClothingWithStats = () => {
    if (selectedItem) {
      const { name } = selectedItem;
      const isEffectItem = (clothingDisplayDescription && clothingDisplayDescription.includes("EFFECT:"));

      return (
        <div className="opacity-item">
          <div style={{ color: Colors.Green, padding: Sizes.Small, textAlign: "center", fontSize: 20 }}>{name}</div>
          <div style={{ 
            color: Colors.Green, 
            paddingBottom: Sizes.Large, 
            textAlign: "center", 
            fontStyle: isEffectItem ? "normal" : "italic", 
            fontSize: 12, 
            width: "80%", 
            margin: "auto" 
          }}>{clothingDisplayDescription}</div>
          <EquipButton
            onClick={() => onClickEquip(selectedItem)}
          >
            EQUIP!
          </EquipButton>
        </div>
      )
    } else {
      return null;
    }
  }

  const onClickItem = (item) => {
    setSelectedItem(item);
  } 

  const WeaponList = () => {
    return (
      <WeaponsList>
        {userWeapons.filter(weapon => { return (weapon.name !== weapon1text) && (weapon.name !== weapon2text)}).map((weapon, index) => {
          return (
            <HoverBorderItem key={index} onClick={() => onClickItem(weapon)}>
              {weapon.name}
            </HoverBorderItem>
          )
        })}
      </WeaponsList>
    )
  }

  const ClothingList = () => {
    return (
      <WeaponsList>
        {userClothing.filter(clothing => clothing.bodyPart === selectedEquipmentType.toLowerCase()).map((clothing, index) => {
          return (
            <HoverBorderItem key={index} onClick={() => onClickItem(clothing)}>
              {clothing.name}
            </HoverBorderItem>
          )
        })}
      </WeaponsList>
    )
  }

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
          {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
        </div>
        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "space-between", paddingTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: "250px", marginLeft: Sizes.Medium }}>
              <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 600 }}>Clothes</div>
              <IconRowContainer style={{ paddingLeft: 20 }}>
                <div className="align-row">
                  <FontAwesomeIcon icon={faFaceDizzy} color={Colors.Green} fontWeight={700} size="xl" />
                  <div className="item-text-large" style={{ paddingLeft: 25, paddingTop: Sizes.Small, paddingBottom: Sizes.Small, paddingRight: Sizes.Small }}>
                    {headItemText}
                  </div>
                </div>
                <FontAwesomeIcon icon={faRotate} className="rotate-icon" color={Colors.Green} fontWeight={700} size="2x" style={{ transition: 'transform 0.5s' }} onClick={() => onClickEquipmentSlot("Head")}/>
              </IconRowContainer>
              <IconRowContainer>
                <div className="align-row">
                  <FontAwesomeIcon icon={faShirt} color={Colors.Green} fontWeight={700} size="2x" />
                  <div className="item-text">
                    {bodyItemText}
                  </div>
                </div>
                <FontAwesomeIcon icon={faRotate} className="rotate-icon" color={Colors.Green} fontWeight={700} size="2x" style={{ transition: 'transform 0.5s' }} onClick={() => onClickEquipmentSlot("Body")}/>
              </IconRowContainer>
              <IconRowContainer style={{ paddingLeft: 15 }}>
                <div className="align-row">
                  <FontAwesomeIcon icon={faSocks} color={Colors.Green} fontWeight={700} size="xl" />
                  <div className="item-text-large">
                    {feetItemText}
                  </div>
                </div>
                <FontAwesomeIcon icon={faRotate} className="rotate-icon" color={Colors.Green} fontWeight={700} size="2x" style={{ transition: 'transform 0.5s' }}  onClick={() => onClickEquipmentSlot("Feet")}/>
              </IconRowContainer>
            </div>
            <div style={{ width: "250px", marginLeft: Sizes.Medium }}>
              <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 600 }}>Weapons</div>
              <IconRowContainer>
                <div className="align-row">
                  <FontAwesomeIcon icon={faGun} color={Colors.Green} fontWeight={700} size="2x" />
                  <div className="item-text">
                    {weapon1text}
                  </div>
                </div>
                <FontAwesomeIcon icon={faRotate} className="rotate-icon" color={Colors.Green} fontWeight={700} size="2x" style={{ transition: 'transform 0.5s' }}  onClick={() => onClickEquipmentSlot("Weapon 1")}/>
              </IconRowContainer>
              <IconRowContainer>
                <div className="align-row">
                  <FontAwesomeIcon icon={faGun} color={Colors.Green} fontWeight={700} size="2x" />
                  <div className="item-text">
                    {weapon2text}
                  </div>
                </div>
                <FontAwesomeIcon icon={faRotate} className="rotate-icon" color={Colors.Green} fontWeight={700} size="2x" style={{ transition: 'transform 0.5s' }}  onClick={() => onClickEquipmentSlot("Weapon 2")}/>
              </IconRowContainer>
            </div>
          </div>
          <div style={{ width: "350px", overflow: "hidden" }}>
            <SlideInFromRight $visible={showDiv} opacity={opacity}>
              {selectedEquipmentType.length > 0 &&
                <>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 600, textAlign: "center", marginBottom: Sizes.Medium }}>Equip {selectedEquipmentType}</div>
                  {renderWeaponList &&
                    <>
                      {WeaponList()} 
                      <WeaponWithStats />   
                    </>
                  }
                  {renderClothingList &&
                    <>
                      {ClothingList()}
                      <ClothingWithStats />
                    </>
                  }
                </>
              }
            </SlideInFromRight>
          </div>
        </div>
      </div>
    </div>
  )
}

const IconRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 10px;
  height: 60px;

  .item-text {
    color: ${Colors.Green}; 
    font-size: ${Sizes.Medium}px;
    padding-left: ${Sizes.Medium}px;
  }

  .item-text-large {
    color: ${Colors.Green}; 
    font-size: ${Sizes.Medium}px;
    padding-left: ${Sizes.Large}px;
  }

  .align-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const SlideInFromRight = styled.div`
  transform: ${props => (props.$visible ? 'translateX(0)' : 'translateX(10%)')};
  opacity: ${props => props.opacity};
  transition: transform 0.2s ease-in-out, opacity 0.2s linear;

  .opacity-item {
    opacity: ${props => props.opacity};
    transition: opacity 0.01s linear;
    width: 100%;
  }
`;

const HoverBorderItem = styled.div`
  border: 2px solid ${Colors.Black};
  transition: border 0.2s ease-in-out;
  color: ${Colors.Green};
  padding: 4px;
  max-width: 300px;

  &:hover {
    border: 2px solid ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`

const EquipButton = styled.div`
  color: ${Colors.Green}; 
  border: 2px solid ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
  margin-bottom: 40px;
`

const WeaponsList = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  padding: 4px;
  height: 120px; 
  overflow-y: scroll;
`