import Battleground from "../components/Battleground"
import YouFound from "../components/YouFound"
import { useSelector, useDispatch } from "react-redux";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";

export default function ParkingLot() {
  const user = useSelector((state) => { return state.user });
  const dispatch = useDispatch();
  const requiresMotorcycleEngine = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "motorcycle" && !user.userInfo.activeQuest.part2;

  const onReceiveUpdate = () => {
    const receiveUpdate = async () => {
      const updatePart2Response = await api.users.progressQuest("part2");
  
      if (updatePart2Response && updatePart2Response.data) {
        console.log(updatePart2Response)
        dispatch(setUserInfo(updatePart2Response.data));
      } 
    }

    receiveUpdate().catch(err => console.error(err));
  }

  if (requiresMotorcycleEngine) {
    return <YouFound item={"An unused motorcycle engine in perfect condition."} sendUpdate={onReceiveUpdate}/>
  }

  return (
    <Battleground location={"parking-lot"} returnLocation={"/city"}/>
  )
}