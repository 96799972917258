import Colors from "../constants/Colors";
import { useSelector } from "react-redux";
import HaltTraveler from "./HaltTraveler";
import YouLookBeatUp from "./YouLookBeatUp";
import YouHaveGoneInsane from "./YouHaveGoneInsane";
import { useEffect, useMemo, useState } from "react";
import { api } from "../axios/api";
import { findIfPlayerHasInitiative, generateAttackResults, playerSanityCheck, getRandomIntMinMax } from "../utilities/helpers";
import { useDispatch } from "react-redux";
import { setCurrentMonster, setCurrentLocation, setPlayerHasInitiative, setActiveBattle, decreaseTurnsOfBonusDmg, setIsInBattleground } from "../store/game";
import { setUserInfo, setUserHp } from "../store/user";
import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import styled from "styled-components";
import HoverItem from "./HoverItem";
import findMonsterImageSrc from "../utilities/findMonsterImageSrc";

export default function Battleground(props) {
  const user = useSelector((state) => { return state.user });
  const game = useSelector((state) => { return state.game });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const monster = game.currentMonster;
  const playerHasInitiative = game.playerHasInitiative;
  const userCannotFight = user.userInfo.hp <= 0 && !monster;
  const userInsane = user.userInfo.sanity <= 0;
  const [rotationDeg, setRotationDeg] = useState(90);

  const hasThirdEye = user.userInfo.loadOut.head && user.userInfo.loadOut.head.id === 8; // third eye
  const hasDiaperEquipped = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 12; // diaper

  const hasNoWeapon = !user.userInfo.loadOut.weapon1;
  const [hasFled, setHasFled] = useState(false);
  const [playerDefeat, setPlayerDefeat] = useState(false);
  const [playerVictory, setPlayerVictory] = useState(false);

  const canFightAgain = !hasFled && !playerDefeat;

  const showMonsterAndBattleResult = (playerVictory || game.currentMonster);
  const battleFinished = (hasFled || playerDefeat || playerVictory);
  const showBattleControls = (!battleFinished && game.currentMonster);

  const [playerBattleText, setPlayerBattleText] = useState("");
  const [enemyBattleText, setEnemyBattleText] = useState("");
  const [sanityText, setSanityText] = useState("");
  // const [newSanity, setNewSanity] = useState(user.userInfo.sanity);
  const [modSanityLoss, setModSanityLoss] = useState(0);
  const [newExperience, setNewExperience] = useState(user.userInfo.exp);
  const [newGold, setNewGold] = useState(user.userInfo.gold);
  const [foundGold, setFoundGold] = useState(0);
  const [experienceText, setExperienceText] = useState("");
  const [lootedItemId, setLootedItemId] = useState(null);
  const [lootText, setLootText] = useState("");

  const memoizedSanityText = useMemo(() => sanityText, [sanityText]);
  const memoizedExperienceText = useMemo(() => experienceText, [experienceText]);
  const memoizedLootText = useMemo(() => lootText, [lootText]);

  const [weapon1, setWeapon1] = useState(null);
  const [weapon2, setWeapon2] = useState(null);

  const weapon1Name = weapon1 ? weapon1.name : "";
  const weapon2Name = weapon2 ? weapon2.name : "";

  useEffect(() => {
    if (!game.currentMonster && !hasNoWeapon && !userCannotFight && !userInsane) {
      initializeCombat();
    }

    dispatch(setCurrentLocation(props.location));
    dispatch(setIsInBattleground(true));

    setWeapon1(user.userInfo.loadOut.weapon1);
    if (user.userInfo.loadOut.weapon2) {
      setWeapon2(user.userInfo.loadOut.weapon2);
    }
  }, []);

  useEffect(() => {
    // handle monster appearance
    let timeout;
    if (monster) {
      timeout = setTimeout(() => {
        setRotationDeg(0);
      }, 100);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [monster]);

  useEffect(() => {
    if (battleFinished) {
      dispatch(setActiveBattle(false));
    }

    if (playerVictory) {
      updateUserStatsAfterVictory().catch(err => console.error(err));
    } else if (playerDefeat || hasFled) {
      updateUserStatsAfterBattle(user.userInfo.hp).catch(err => console.error(err));
    }
  }, [battleFinished, playerVictory, playerDefeat, hasFled]);

  // special anti-cheat useEffect for page unload.
  // we must clear the monster if the user reloads while having defeated.
  // i'm going to comment it out for now.
  // TODO: if this become a problem, fix it.
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Cancel the event
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set
  //     event.returnValue = '';

  //     if (monster.hp <= 0) {
  //       dispatch(setCurrentMonster(null));
  //     }
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // get the monster, then get loot and set initiative.
  const initializeCombat = () => {

    // get Monster and potential loot!!
    const getMonsterAndLoot = async () => {
      const getMonsterAndLootResponse = await api.monster.getMonsterAndLoot(props.location);

      if (!getMonsterAndLootResponse || !getMonsterAndLootResponse.data) {
        console.log("something went wrong");
        return;
      }
      if (getMonsterAndLootResponse && getMonsterAndLootResponse.data) {
        let selectedMonster = getMonsterAndLootResponse.data.monster;
        dispatch(setCurrentMonster(selectedMonster));
        dispatch(setActiveBattle(true));

        if (getMonsterAndLootResponse.data.itemId) {
          setLootedItemId(getMonsterAndLootResponse.data.itemId);
        }

        if (getMonsterAndLootResponse.data.lootText) {
          setLootText(getMonsterAndLootResponse.data.lootText)
        }
        
        return selectedMonster;
      }
    }


    getMonsterAndLoot()
      .then(monster => {
        console.log("monster: ", monster);
        handleSanityCheck(monster.sanityCheck, monster.sanityLoss);
      })
      .catch(err => console.error(err));
  
    let playerInitiative = findIfPlayerHasInitiative(user);
    dispatch(setPlayerHasInitiative(playerInitiative));
  }

  const handleSanityCheck = (monsterSanityCheck, monsterSanityLoss) => {
    // case one: player fails sanity check
    if (!playerSanityCheck(user, monsterSanityCheck)) {

      // player gets a bonus from mind, half the stat, to reduction of sanity loss
      let sanityLoss = getRandomIntMinMax(1, monsterSanityLoss);
      let mindBonus = Math.floor(user.userInfo.mind / 2);

      // if the player loses the sanity check, they still must lose at least one sanity point.
      if (sanityLoss - mindBonus >= 1) {
        sanityLoss -= mindBonus;
      } else {
        sanityLoss = 1;
      }
      // if the player is wearing the diaper, they can have their sanity loss reduced by 2 points.
      if (hasDiaperEquipped && (sanityLoss - 2 >= 1)) {
        sanityLoss -= 2;
      } else if (hasDiaperEquipped && (sanityLoss - 2 < 1)) {
        sanityLoss = 1;
      }

      console.log("sanityLoss", sanityLoss);
      setModSanityLoss(sanityLoss);
      setSanityText("The ordeal leaves you with less sanity than before...");

    // case two: pass sanity check
    } else {
      setModSanityLoss(0);
      setSanityText("");
    }
  }

  const onClickFlee = () => {
    setHasFled(true);
  }

  const updateUserStatsAfterVictory = async () => {
    let experiencePoints = getRandomIntMinMax(monster.expGranted.low, monster.expGranted.high);

    // gain 10% experience gained from third eye
    if (hasThirdEye) {
      experiencePoints = Math.floor(experiencePoints * 1.1);
    }

    let totalExp = user.userInfo.exp + experiencePoints;
    let gold = getRandomIntMinMax(1,11) + (monster.rarity * 10);
    let totalGold = user.userInfo.gold + gold;
    setNewExperience(totalExp);
    setExperienceText(`Gained ${experiencePoints} experience points.`);
    setNewGold(totalGold);
    setFoundGold(gold);

    // for special monsters, show a victory text
    if (monster && monster.name) {
      if (monster.name.includes("Bigfoot") || monster.name.includes("Cyberdemon") || monster.name.includes("OBELISK") || monster.name.includes("Dracula") || monster.name.includes("Alien")) {
        postNews().catch(err => console.error(err));
      }
    }

    const updateAfterVictoryResponse = await api.users.updateUserAfterBattle(user.userInfo._id, {
      hp: user.userInfo.hp,
      sanityLoss: modSanityLoss,
      exp: totalExp,
      gold: totalGold,
      itemId: lootedItemId
    });

    if (!updateAfterVictoryResponse || !updateAfterVictoryResponse.data) {
      console.log("Something went wrong.")
    }

    if (updateAfterVictoryResponse && updateAfterVictoryResponse.data) {
      dispatch(setUserInfo(updateAfterVictoryResponse.data));
    }
  }

  // save user's new hp and sanity loss to the database after fleeing / being defeated.
  const updateUserStatsAfterBattle = async (newHp) => {

    let sanityLossForUpdate = modSanityLoss;

    // if the player has died, they automatically lose an additional sanity point,
    // on top of the already calculated amount.
    if (newHp === 0) {
      sanityLossForUpdate += 1;
    }

    const updateAfterBattleResponse = await api.users.updateUserAfterBattle(user.userInfo._id, {
      hp: newHp,
      sanityLoss: sanityLossForUpdate,
      exp: newExperience,
      gold: newGold,
    });

    if (!updateAfterBattleResponse || !updateAfterBattleResponse.data) {
      console.log("Something went wrong.")
    }

    if (updateAfterBattleResponse && updateAfterBattleResponse.data) {
      dispatch(setUserInfo(updateAfterBattleResponse.data));
    }
  }

  const onClickReturn = () => {
    dispatch(setCurrentMonster(null));
    navigate(props.returnLocation);
    dispatch(setIsInBattleground(false));
  }

  const onClickFightAnotherMonster = () => {
    dispatch(setCurrentMonster(null));
    setRotationDeg(90);
    setHasFled(false);
    setPlayerDefeat(false);
    setPlayerVictory(false);
    setPlayerBattleText("");
    setEnemyBattleText("");
    setExperienceText("");
    setLootText("");
    initializeCombat();
  }

  // events after being defeated while not having initiative
  const handleFirstRoundDefeat = () => {
    setPlayerDefeat(true);
    setPlayerBattleText("");

    dispatch(setUserHp(0));
  }

  // events after victory with initiative
  const handleFirstRoundVictory = () => {
    setPlayerVictory(true);
    setEnemyBattleText("");

    dispatch(setCurrentMonster({
      ...monster,
      hp: 0
    }));
  }

  const postNews = async () => {
    const message = `${user.userInfo.username} defeated ${monster.name}!!`;
    const postNewsResponse = await api.news.postNews(message);
    
    if (!postNewsResponse || !postNewsResponse.data) {
      console.log("Something went wrong in postNews()");
    }
  }

  const handleClickWeapon1 = () => {
    onClickWeapon(weapon1)
  } 

  const handleClickWeapon2 = () => {
    onClickWeapon(weapon2)
  } 

  const onClickWeapon = (weapon) => {
    const { playerText, monsterText, playerWeaponDamage, monsterDamage } = generateAttackResults(weapon, user, monster);

    let modPlayerWeaponDamage = playerWeaponDamage;

    // if the player has bonus damage from pills, apply it here.
    if (game.turnsOfBonusDmg > 0) {
      modPlayerWeaponDamage += 2;
      dispatch(decreaseTurnsOfBonusDmg());
    }
    
    let newMonsterHp = monster.hp - modPlayerWeaponDamage;
    let newPlayerHp = user.userInfo.hp - monsterDamage;

    // if the monster HP hits zero and the player has initiative, they win.
    if (newMonsterHp <= 0 && playerHasInitiative) {
      setPlayerBattleText(playerText)    
      handleFirstRoundVictory();
      return;
    }

    // if player HP hits zero and they don't have initiative, they lose.
    if (!playerHasInitiative && newPlayerHp <= 0) {
      setEnemyBattleText(monsterText);
      handleFirstRoundDefeat();
      return;
    }

    if (newMonsterHp <= 0) {
      newMonsterHp = 0;
      setPlayerVictory(true);
    }

    if (newPlayerHp <= 0) {
      newPlayerHp = 0;
      setPlayerDefeat(true);
    }

    dispatch(setCurrentMonster({
      ...monster,
      hp: newMonsterHp
    }));

    dispatch(setUserHp(newPlayerHp));

    setEnemyBattleText(monsterText);
    setPlayerBattleText(playerText)    
  }

  const BattleResultTexts = () => {

    if (battleFinished) {
      return (
        <>
          {hasFled &&
            <div style={{ color: Colors.Green, textAlign: "center", marginTop: "12px", }}>You have fled to safety!</div>
          }
          {playerVictory &&
            <div style={{ color: Colors.Gold, textAlign: "center", marginTop: "12px", fontSize: Sizes.Medium, fontStyle: "italic", fontWeight: 600 }}>You are victorious!!!</div>
          }
          {playerDefeat &&
            <>
              <div style={{ color: Colors.Red, textAlign: "center", marginTop: "12px", fontSize: Sizes.Medium, fontWeight: 700 }}>You have lost. Very badly.</div>
              <div style={{ color: Colors.Red, textAlign: "center", marginTop: "12px", fontSize: "12px", fontStyle: "italic" }}>You are knocked unconscious and lose a chunk of your sanity...</div>
            </>
          }
          {memoizedSanityText.length > 0 && !playerDefeat &&
            <div className="battleground-italic-text">{memoizedSanityText}</div>
          }
          {memoizedExperienceText.length > 0 &&
            <div className="battleground-italic-text">
              {memoizedExperienceText}
              {foundGold > 0 && 
                <PaddingLeftSpan>Found <span style={{ color: Colors.Gold }}>{foundGold} gold!</span><PaddingLeftSpan>{memoizedLootText}</PaddingLeftSpan></PaddingLeftSpan>
              }
            </div>
          }
          {canFightAgain &&
            <div className="battleground-link" onClick={onClickFightAnotherMonster}>Fight Another Monster</div>
          }
          <div className="battleground-link" onClick={onClickReturn}>Return to Map</div>
        </>
      )
    } else { 
      return null;
    }
  }

  if (userInsane) {
    return <YouHaveGoneInsane returnLocation={props.returnLocation} />
  }

  if (userCannotFight) {
    return <YouLookBeatUp returnLocation={props.returnLocation} />
  }

  if (hasNoWeapon) {
    return <HaltTraveler returnLocation={props.returnLocation} />
  }

  return (
    <div style={{ width: "100%", paddingTop: Sizes.Small }}>
      {showMonsterAndBattleResult && 
        <>
          <ActiveMonster $rotation={rotationDeg}>
            <div style={{ color: Colors.White, fontWeight: 700, fontSize: Sizes.Medium, fontStyle: "italic", width: "fit-content", margin: "auto" }}>{game.currentMonster.name}</div>
            <div style={{ width: "fit-content", margin: "auto" }}>
              <img alt="monster" style={{ height: "250px" }} src={findMonsterImageSrc(monster.imageUrl)}/>
            </div>
            <div className="battleground-monster-text" style={{ fontSize: "18px", fontWeight: 600, marginBottom: Sizes.Small }}>{`HP: (${monster.hp}/${monster.maxHp})`}</div>
            <div className="battleground-monster-text" style={{ fontStyle: "italic" }}>{game.currentMonster.description}</div>     
          </ActiveMonster>
          {playerHasInitiative && 
            <>
              {playerBattleText &&
                <div className="battleground-battle-text">{playerBattleText}</div>
              }
              {enemyBattleText &&
                <div className="battleground-battle-text">{enemyBattleText}</div>
              }
            </>
          }
          {!playerHasInitiative && 
            <>
              {enemyBattleText &&
                <div className="battleground-battle-text">{enemyBattleText}</div>
              }
              {playerBattleText &&
                <div className="battleground-battle-text">{playerBattleText}</div>
              }
            </>
          }
        </>
      }
      {showBattleControls &&
        <>
          <BattleControls>
            <HoverItem onClick={handleClickWeapon1} text={weapon1Name}/>
            {weapon2Name.length > 0 && 
              <HoverItem onClick={handleClickWeapon2} text={weapon2Name}/>
            }
          </BattleControls>
          <FleeButton onClick={onClickFlee}>FLEE</FleeButton>
        </>
      }
      <BattleResultTexts />
    </div>
  )
}

const ActiveMonster = styled.div`
  transform-origin: 90% 90%;
  transform: rotate3d(0, 1, 0, ${props => props.$rotation ? props.$rotation : "0"}deg);
  transition: 2s ease-in-out;
`

const FleeButton = styled.div`
  color: ${Colors.black}; 
  margin: 24px auto auto auto; 
  width: fit-content; 
  padding: ${Sizes.ExtraSmall}px ${Sizes.Small}px; 
  background-color: ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
`

const PaddingLeftSpan = styled.span`
  padding-left: ${Sizes.Small}px;
`

const BattleControls = styled.div`
  margin: 4px auto; 
  display: flex; 
  flex-direction: row; 
  max-width: 100%;
`