import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useSelector } from "react-redux";
import SpeechText from "../components/SpeechText";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HoverItem from "../components/HoverItem";
import { setUserInfo } from "../store/user";
import { useDispatch } from "react-redux";
import { api } from "../axios/api";

export default function Meteorite(props) {
  const user = useSelector((state) => { return state.user});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requiresIsotope = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "shuttle" && !user.userInfo.activeQuest.part2;

  const dialogue = {
    nothing: "There's doesn't seem to be anything here.",
    foundItem: "You've discovered some kind of glowing radioactive isotope. Maybe it could kickstart the reactor on the shuttle.",
    extractedItem: "You put the radioactive isotope in your pocket."
  }

  const firstDialogue = requiresIsotope ? dialogue.foundItem : dialogue.nothing;
  const [text, setText] = useState(firstDialogue);

  const onClickBack = () => {
    navigate("/psychic-plane");
  }

  const onClickTakeIsotope = () => {
    const updatePart2 = async () => {
      const updatePart2Response = await api.users.progressQuest("part2");
  
      if (updatePart2Response && updatePart2Response.data) {
        console.log(updatePart2Response)
        dispatch(setUserInfo(updatePart2Response.data));
        setText(dialogue.extractedItem);
      } 
    }

    updatePart2().catch(err => console.error(err));
  }

  return (
    <div>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div style={{ color: Colors.Green, textAlign: "center", fontSize: Sizes.Large, padding: Sizes.ExtraSmall, fontStyle: "italic", fontWeight: 700 }}>
        Radioactive Meteorite
      </div>
      <div>
        <div style={{ width: "300px", padding: "0px 20px", margin: "auto" }}>
          <div style={{ color: Colors.Green, height: "24px", marginTop: Sizes.Large }}><SpeechText text={text}/></div>
        </div>
        {requiresIsotope &&
          <div style={{ width: "300px", margin: "auto", marginTop: Sizes.ExtraLarge }}>
            <HoverItem onClick={onClickTakeIsotope} text="Take the Isotope"/>
          </div> 
        }
      </div>

    </div>
  )
}