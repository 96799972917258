import Battleground from "../components/Battleground"
import { useDispatch, useSelector } from "react-redux";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";
import YouFound from "../components/YouFound";

export default function Area51() {
  const user = useSelector((state) => { return state.user });
  const dispatch = useDispatch();
  const requiresShipEngine = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "shuttle" && !user.userInfo.activeQuest.part1;

  const onReceiveUpdate = () => {
    const receiveUpdate = async () => {
      const updatePart1Response = await api.users.progressQuest("part1");
  
      if (updatePart1Response && updatePart1Response.data) {
        dispatch(setUserInfo(updatePart1Response.data));
      } 
    }

    receiveUpdate().catch(err => console.error(err));
  }

  if (requiresShipEngine) {
    return <YouFound item={"A strange alien computer!"} sendUpdate={onReceiveUpdate}/>
  }

  return (
    <Battleground location={"area51"} returnLocation={"/desert"}/>
  )
}