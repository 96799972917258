import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { api } from "../axios/api";
import { lightShade } from "../constants/ASCIICodes";
import useIsMobile from "../hooks/useIsMobile";

export default function Online() {
  const [players, setPlayers] = useState([]); 
  const isMobile = useIsMobile();
  const SOCIAL_MEDIA = "socialmedia";
  const LEADERBOARDS = "leaderboards";
  const NEWS = "news";
  const [selectedTab, setSelectedTab] = useState(SOCIAL_MEDIA);
  const socialMediaSelected = selectedTab === SOCIAL_MEDIA;
  const leaderboardsSelected = selectedTab === LEADERBOARDS;
  const newsSelected = selectedTab === NEWS;
  const [message, setMessage] = useState("");
  const [news, setNews] = useState([]);
  const [messages, setMessages] = useState([]);
  const chatRef = useRef(null);
  const newsRef = useRef(null);
  const sliceLimit = isMobile ? 7 : 9;

  const topTenByLevel = players.sort((a, b) => {
    if (a.level > b.level) {
      return -1;
    }
    if (a.level < b.level) {
      return 1;
    }
    return 0;
  }).slice(0,sliceLimit);

  const topTenByWealth = players.sort((a, b) => {
    if (a.gold > b.gold) {
      return -1;
    }
    if (a.gold < b.gold) {
      return 1;
    }
    return 0;
  }).slice(0,sliceLimit);

  const topTenByLeastSane = players.sort((a, b) => {
    if (a.sanity > b.sanity) {
      return 1;
    }
    if (a.sanity < b.sanity) {
      return -1;
    }
    return 0;
  }).slice(0,sliceLimit);

  const getAllMessages = async () => {
    const getAllMessagesResponse = await api.messages.getAllMessages();

    if (!getAllMessagesResponse || !getAllMessagesResponse.data) {
      console.log("something went wrong with getAllMessages()");
    }

    if (getAllMessagesResponse && getAllMessagesResponse.data) {
      setMessages(getAllMessagesResponse.data);
    }
  }

  const getAllNews = async () => {
    const getAllNewsResponse = await api.news.getAllNews();

    if (!getAllNewsResponse || !getAllNewsResponse.data) {
      console.log("something went wrong with getAllMessages()");
    }

    if (getAllNewsResponse && getAllNewsResponse.data) {
      // only display 50 most recent messages
      let endIndex = getAllNewsResponse.data.length;
      let firstIndex = endIndex - 50;
      let newsToDisplay = getAllNewsResponse.data.slice(firstIndex, endIndex);
      setNews(newsToDisplay);
    }
  }

  useEffect(() => {
    const getAllUsers = async () => {
      const getAllUsersResponse = await api.users.getAllUsers();

      if (!getAllUsersResponse || !getAllUsersResponse.data) {
        console.log("something went wrong with getAllUsers()");
      }

      if (getAllUsersResponse && getAllUsersResponse.data) {
        setPlayers(getAllUsersResponse.data);
      }
    }

    getAllMessages()
      .then(res => {
        scrollMessagesToBottom();
      })
      .catch(err => console.error(err));
    getAllUsers().catch(err => console.error(err));
    getAllNews().catch(err => console.error(err));
  }, []);

  useEffect(() => {
    scrollMessagesToBottom();
  }, [selectedTab, messages]);

  const onClickTab = (tabName) => {
    setSelectedTab(tabName);
  }

  const scrollMessagesToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    if (newsRef.current) {
      newsRef.current.scrollTop = newsRef.current.scrollHeight;
    }
  }

  const onClickSend = () => {
    if (!message) {
      return;
    }

    const sendMessage = async () => {
      const sendMessageResponse = await api.messages.postMessage(message);
    
      console.log(sendMessageResponse.data);
    }

    sendMessage()
      .then(() => {
        // clear message after post
        setMessage("");
        // get messages again
        getAllMessages().catch(err => console.error(err));
      }) 
      .catch(err => console.error(err))
  }

  const onChangeInput = (e) => {
    setMessage(e.target.value)
  }

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClickSend();
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", marginTop: Sizes.Small }}>
        <TabButton selected={socialMediaSelected} onClick={() => onClickTab(SOCIAL_MEDIA)}>Social Media</TabButton>
        <TabButton selected={leaderboardsSelected} onClick={() => onClickTab(LEADERBOARDS)}>Leaderboards</TabButton>
        <TabButton selected={newsSelected} onClick={() => onClickTab(NEWS)}>News</TabButton>
      </div>
      <SearchBarContent />
      <div style={{ color: Colors.Green, display: "flex", flexDirection: "row", justifyContent: "space-between", overflow: "hidden" }}>
        {Array.from(Array(60).keys()).map(num => 
          <UnicodeSpan key={num} >{lightShade}</UnicodeSpan>
        )}
      </div>
      {socialMediaSelected &&
        <div style={{ height: "100%" }}>
          <div style={{ border: `1px solid ${Colors.Green}`, margin: `${Sizes.Large}px ${Sizes.Large}px 0px ${Sizes.Large}px`, height: "100%" }}>
            <div className="online-title">
              #chat
            </div>
            <div ref={chatRef} style={{ height: "300px", overflowY: "scroll", paddingBottom: Sizes.Medium }}>
              {messages.map((msg, i) => {
                let officialDate = new Date(msg.updatedAt).toLocaleString();
                return (
                  <MessageContainer key={i}>
                    <div className="timezone">
                      <span className="name">{msg.postedBy}:</span> 
                      {officialDate} PST
                    </div>
                    <div style={{ color: Colors.White, paddingTop: Sizes.ExtraSmall, paddingLeft: Sizes.ExtraSmall }}>{msg.text}</div>
                  </MessageContainer>
                )
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: Sizes.Large, marginRight: Sizes.Large }}>
            <GreenInput 
              type="text"
              onChange={onChangeInput}
              onKeyDown={onPressEnter}
              id="message" 
              name="message" 
              value={message}
            />
            <div style={{ backgroundColor: Colors.Green, color: Colors.Black, padding: 10, cursor: "pointer" }} onClick={onClickSend}>SEND</div>  
          </div>
        </div>
      }
      {leaderboardsSelected &&
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: isMobile ? 0 : Sizes.ExtraLarge, paddingRight: isMobile ? 0 : Sizes.ExtraLarge, paddingTop: Sizes.Medium }}>
          <Leaderboard>
            <LeaderboardHeader>Most Powerful</LeaderboardHeader>
            {topTenByLevel.map((player, index) => 
              <div key={player.username} >
                <div style={{ color: Colors.White }}>{index + 1}. {player.username} </div>
                <div style={{ color: Colors.Green }}>Level: {player.level}</div>
              </div>
            )}
          </Leaderboard>
          <Leaderboard>
            <LeaderboardHeader>Wealthiest</LeaderboardHeader>
            {topTenByWealth.map((player, index) => 
              <div key={player.username} >
                <div style={{ color: Colors.White }}>{index + 1}. {player.username} </div>
                <div style={{ color: Colors.Gold }}>Gold: {player.gold}</div>
              </div>
            )}
          </Leaderboard>
          <Leaderboard>
            <LeaderboardHeader>Least Sane</LeaderboardHeader>
            {topTenByLeastSane.map((player, index) => 
              <div key={player.username}>
                <div style={{ color: Colors.White }}>{index + 1}. {player.username} </div>
                <div style={{ color: Colors.Red }}>Sanity: {player.sanity}</div>
              </div>
            )}
          </Leaderboard>
        </div>
      }
      {newsSelected &&
        <div style={{ display: "flex", flexDirection: "row", height: "400px" }}>
          <div style={{ border: `1px solid ${Colors.Green}`, width: "100%", margin: Sizes.Large, height: "100%" }}>
            <div className="online-title">
              #news
            </div>
            <div ref={newsRef} style={{ height: "320px", overflowY: "scroll", paddingBottom: Sizes.Medium }}>
              {news.map((msg, i) => {
                let officialDate = new Date(msg.updatedAt).toLocaleString();
                let isInsanityMsg = msg.text.toLowerCase().includes("insane");
                return (
                  <MessageContainer key={i}>
                    <div className="timezone">{officialDate} PST</div>
                    <div className="news" style={{ color: isInsanityMsg ? Colors.Red : Colors.White, fontWeight: isInsanityMsg ? 600 : 400 }}>{msg.text}</div>
                  </MessageContainer>
                )
              })}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const TabButton = styled.div`
  color: ${props => props.selected ? Colors.Black : Colors.Green}; 
  width: fit-content; 
  margin-left: 12px;
  padding: ${Sizes.ExtraSmall}px ${Sizes.Small}px; 
  background-color: ${props => props.selected ? Colors.Green : Colors.Black}; 
  cursor: pointer; 
  font-weight: 600;
  border-left: 1px solid ${Colors.Green};
  border-right: 1px solid ${Colors.Green};
  border-top: 1px solid ${Colors.Green};
`

const SearchBarContent = styled.div`
  background-color: ${Colors.Green};
  height: 12px;
`

const LeaderboardHeader = styled.div`
  color: ${Colors.White}; 
  font-weight: 600;
  margin-bottom: 8px;
`

const Leaderboard = styled.div`
  width: 160px;
  padding: 8px;
  border: 3px double ${Colors.Green};
`

const UnicodeSpan = styled.span`
  font-family: "Lucida Sans Unicode";
`

const GreenInput = styled.input`
  color: ${Colors.Green}; 
  font-size: 14px;
  background-color: ${Colors.Black}; 
  border-color: ${Colors.Green}; 
  border-style: solid;
  width: 94%;
`

const MessageContainer = styled.div`
  padding: 5px;

  .timezone {
    font-style: italic;
    font-size: 10px;
    padding-left: 5px;
    color: ${Colors.LightGray};
  }

  .name {
    font-weight: 600;
    font-size: 12px;
    padding-right: 5px;
    color: ${Colors.White};
  }
`