import Battleground from "../components/Battleground"
import YouFound from "../components/YouFound"
import { useSelector, useDispatch } from "react-redux";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";

export default function Tower() {
  const user = useSelector((state) => { return state.user });
  const dispatch = useDispatch();
  const requiresDemonSoul = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "motorcycle" && !user.userInfo.activeQuest.part3;

  const onReceiveUpdate = () => {
    const receiveUpdate = async () => {
      const updatePart3Response = await api.users.progressQuest("part3");
  
      if (updatePart3Response && updatePart3Response.data) {
        dispatch(setUserInfo(updatePart3Response.data));
      } 
    }

    receiveUpdate().catch(err => console.error(err));
  }

  if (requiresDemonSoul) {
    return <YouFound item={"A perfectly good demon's soul, lying on somebody's desk."} sendUpdate={onReceiveUpdate}/>
  }

  return (
    <Battleground location={"tower"} returnLocation={"/downtown"}/>
  )
}