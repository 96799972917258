import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://api.nanonomicron.com/'
  // baseURL: 'http://localhost:8080'
})

instance.interceptors.request.use(function (config) {
  var token = localStorage.getItem("nanoToken");

  config.headers.authorization =  token ? `Bearer ${token}` : '';
     
  return config;
})