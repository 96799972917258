import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './user';
import gameReducer from './game';

const persistConfig = {
  key: 'root',
  storage,
}

const persistGameConfig = {
  key: 'game',
  storage,
}

const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedGameReducer = persistReducer(persistGameConfig, gameReducer);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    game: persistedGameReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export const persistor = persistStore(store);
