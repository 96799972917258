import alligator from "../assets/monsters/alligator.png";
import cannibal from "../assets/monsters/cannibal.png";
import neighborhood_guy from "../assets/monsters/neighborhood_guy.png";
import leftovers from "../assets/monsters/leftovers.png";
import pigeon from "../assets/monsters/pigeon.png";
import laptop from "../assets/monsters/laptop.png";
import bat from "../assets/monsters/bat2.png";
import leather_mutant from "../assets/monsters/leather_mutant.png";
import bicycle from "../assets/monsters/bicycle.png";
import centipede from "../assets/monsters/centipede.png";
import ghost from "../assets/monsters/ghost.png";
import flukeman from "../assets/monsters/flukeman.png";
import tiger from "../assets/monsters/tiger.png";
import chupacabra from "../assets/monsters/chupacabra.png";
import footballer from "../assets/monsters/footballer.png";
import retiari from "../assets/monsters/retiari2.png";
import salaryman from "../assets/monsters/salaryman.png";
import scientist from "../assets/monsters/scientist.png";
import brain_jar from "../assets/monsters/brain_jar.png";
import ceo from "../assets/monsters/ceo.png"
import jackalope from "../assets/monsters/jackalope.png";
import werewolf from "../assets/monsters/werewolf.png";
import hunter from "../assets/monsters/hunter.png";
import beer_ghost from "../assets/monsters/beer_ghost.png";
import jersey_devil from "../assets/monsters/jersey_devil.png";
import bigfoot from "../assets/monsters/bigfoot.png";
import mummy from "../assets/monsters/mummy.png";
import obelisk from "../assets/monsters/obelisk.png";
import alien from "../assets/monsters/alien.png";
import mib from "../assets/monsters/mib.png";
import succubus from "../assets/monsters/succubus.png";
import priestess from "../assets/monsters/priestess.png";
import wanderer from "../assets/monsters/wanderer.png";
import cyberdemon from "../assets/monsters/cyberdemon.png";
import reptilian from "../assets/monsters/reptilian.png";
import psychic from "../assets/monsters/psychic2.png";
import flesh_golem from "../assets/monsters/flesh_golem.png";
import dracula from "../assets/monsters/dracula.png";

export default function findMonsterImageSrc(imageUrl) {
  switch (imageUrl) {
    case "laptop":
      return laptop;
    case "leftovers":
      return leftovers;
    case "neighborhood_guy":
      return neighborhood_guy;
    case "cannibal":
      return cannibal;
    case "bat":
      return bat;
    case "pigeon":
      return pigeon;
    case "centipede":
      return centipede;
    case "leather_mutant":
      return leather_mutant;
    case "bicycle":
      return bicycle;
    case "ghost":
      return ghost;
    case "flukeman":
      return flukeman;
    case "tiger":
      return tiger;
    case "chupacabra":
      return chupacabra;
    case "footballer":
      return footballer;
    case "retiari":
      return retiari;
    case "salaryman":
      return salaryman;
    case "scientist":
      return scientist;
    case "ceo":
      return ceo;
    case "brain_jar":
      return brain_jar;
    case "jackalope":
      return jackalope;
    case "werewolf":
      return werewolf;
    case "hunter":
      return hunter;
    case "beer_ghost":
      return beer_ghost;
    case "jersey_devil":
      return jersey_devil;
    case "bigfoot":
      return bigfoot;
    case "mummy":
      return mummy;
    case "obelisk":
      return obelisk;
    case "alien":
      return alien;
    case "mib":
      return mib;
    case "succubus":
      return succubus;
    case "priestess":
      return priestess;
    case "wanderer":
      return wanderer;
    case "reptilian":
      return reptilian;
    case "cyberdemon":
      return cyberdemon;
    case "psychic":
      return psychic;
    case "flesh_golem":
      return flesh_golem;
    case "dracula":
      return dracula;
    default:
      return alligator;
  }
}