import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useSelector } from "react-redux";
import mall from "../assets/npcs/mall3.png";
import SpeechText from "../components/SpeechText";
import Traits from "../constants/Traits";
import { api } from "../axios/api";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/user";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ShoppingMall(props) {
  const user = useSelector((state) => { return state.user});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConArtist = user.userInfo.trait === Traits.ConArtist;
  let availableGold = user.userInfo.gold;
  const PRICE_LOWER_LIMIT = 600;
  const shopKeepDialogue = {
    conArtistDialogue: {
      intro: "How can we help you?",
      fail: "Hey sorry, this isn't free.",
      success: "Thanks!! Wow!!"
    },
    regularDialogue: {
      intro: "Ugh, another lunatic.",
      fail: "Hey! You need to pay for that!",
      success: "Here you go, please don't hurt me."
    }
  }

  const activeTextSource = isConArtist ? shopKeepDialogue.conArtistDialogue : shopKeepDialogue.regularDialogue;
  const [text, setText] = useState(activeTextSource.intro);
  const usersBoughtItems = user.userInfo.weaponInventory;
  const [availableWeapons, setAvailableWeapons] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  // update user price according to their discount, 10 percent if con artist.
  const getRealPrice = (price) => {
    let realPrice = price;
    if (isConArtist) {
      realPrice = price * .9;
    }
    return realPrice;
  }

  useEffect(() => {
    const getAllAvailableWeapons = async () => {
      var getAllAvailableWeaponsResponse = await api.items.getAllAvailableWeapons();

      if (!getAllAvailableWeaponsResponse || !getAllAvailableWeaponsResponse.data) {
        console.log("something went wrong");
        return;
      }
      if (getAllAvailableWeaponsResponse && getAllAvailableWeaponsResponse.data) {
        setAvailableWeapons(getAllAvailableWeaponsResponse.data);
      }
    }
    getAllAvailableWeapons().catch(err => console.error(err));
  }, []);

  // save user's new gold amount to the database
  const updateUserGold = async (newGold) => {

    var updateUserStatsResponse = await api.users.updateUserStats(user.userInfo._id, {
      gold: newGold
    });

    if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
      console.log("Something went wrong.")
    }

    if (updateUserStatsResponse && updateUserStatsResponse.data) {
      dispatch(setUserInfo(updateUserStatsResponse.data));
    }
  }

  const onClickBuy = (item) => {
    const addItemToInventory = async () => {
      var addItemResponse = await api.users.updateUserWeaponInventory(item);

      if (!addItemResponse) {
        console.log("something went wrong with adding item to inventory");
        return;
      }

      if (addItemResponse) {
        console.log("Purchased.");
        props.setNeedsUpdate(true);
      }
    }

    if (getRealPrice(item.value) > availableGold) {
      setText(activeTextSource.fail);
      return;
    } else if (usersBoughtItems.some(boughtItem => boughtItem.name === item.name)) {
      setText("Already bought this item.");
      return;
    } else {
      addItemToInventory().catch(err => console.error(err));
      let remainingGold = availableGold - getRealPrice(item.value);
      setSelectedItem(null);
      updateUserGold(remainingGold).catch(err => console.error(err));
      setText(activeTextSource.success);
    }
  }

  const filterConditions = (item) => {
    // do not show items in the shop after they've been purchased
    return !usersBoughtItems.some(weapon => weapon.name === item.name)
  }

  const onSelectItem = (item) => {
    setSelectedItem(item);
  }

  const onClickBack = () => {
    navigate("/downtown");
  }

  const RenderShopInventory = () => {
    return (
      <div style={{ backgroundColor: "#222222", height: "200px", overflowY: "scroll", border: "3px outset", width: "300px", margin: "auto" }}>
        {availableWeapons.filter(filterConditions).filter(weapon => weapon.value > PRICE_LOWER_LIMIT).map((item, index) => {
          return (
            <div key={index} style={{ padding: Sizes.Small, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ color: Colors.White }}>
                {item.name}
              </div>
              <div style={{ display: "flex", flexDirection: "row", color: Colors.Gold, alignItems: "center" }}>
                <div style={{ fontWeight: 600, paddingRight: Sizes.Small, width: 70 }}>{getRealPrice(item.value)} G</div>
                <div 
                  style={{ border: `1px solid ${Colors.Gold}`, borderRadius: Sizes.Small, padding: Sizes.ExtraSmall, cursor: "pointer" }} 
                  onClick={() => onSelectItem(item)}
                >
                  info
                </div>
              </div>
            </div>
          )
        })}
      </div>
      
    )
  }

  const WeaponWithStats = () => {
    if (selectedItem) {
      const { name, flavorText, relatedAttribute } = selectedItem;
      // const attributeBonus = Math.floor(user.userInfo[relatedAttribute] / 2);
      // test using whole number
      const attributeBonus = user.userInfo[relatedAttribute];
      let damageText = `DMG: ${selectedItem.baseDmg.rolls + attributeBonus} - ${(selectedItem.baseDmg.d * selectedItem.baseDmg.rolls) + attributeBonus}`;
      let relatedAttributeText = `Related Attribute: ${relatedAttribute}`

      return (
        <div style={{ maxWidth: "300px" }}>
          <div style={{ color: Colors.Green, padding: Sizes.Small, textAlign: "center", fontSize: 20 }}>{name}</div>
          <div style={{ color: Colors.Green, paddingBottom: Sizes.Small, textAlign: "center", fontStyle: "italic", fontSize: 12, width: "80%", margin: "auto" }}>{flavorText}</div>
          <div style={{ color: Colors.Green, textAlign: "center", fontSize: 14 }}>{relatedAttributeText}</div>
          <div style={{ color: Colors.Green, textAlign: "center", fontSize: 20, marginBottom: Sizes.Small }}>{damageText}</div>
          <BuyButton
            onClick={() => onClickBuy(selectedItem)}
          >
            BUY!
          </BuyButton>
        </div>
      )
    } else {
      return null;
    }
  }

  return (
    <div>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div className="page-title">
        Department Store
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignContent: "start", flexWrap: "wrap", width: "100%" }}>
        <div style={{ width: "300px", padding: "0px 20px" }}>
          <img style={{ width: "100%" }} src={mall} alt="mall"/>
          <div style={{ color: Colors.Green, height: "24px" }}><SpeechText text={text}/></div>
        </div>
        <div style={{ maxWidth: "450px", margin: "auto", marginTop: Sizes.Medium, height: Sizes.InsideHeight }}>
          {RenderShopInventory()}
          <WeaponWithStats />
        </div>
      </div>
    </div>
  )
}

const BuyButton = styled.div`
  color: ${Colors.Gold}; 
  border: 2px solid ${Colors.Gold}; 
  cursor: pointer; 
  font-weight: 600;
  font-size: 20px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
`