import { api } from "../axios/api";
import { useState, useEffect } from "react";
import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Register() {
  const navigate = useNavigate();
  const [inputOpacity, setInputOpacity] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [registerError, setRegisterError] = useState("");
  const [registerSuccessMessage, setRegisterSuccessMessage] = useState("");
  const [registrationInfo, setRegistrationInfo] = useState({
    username: "",
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    setInputOpacity(1);
    setTimeout(() => {
      setOpacity(1);
    }, 750);
  }, []);

  const onChangeInput = (e) => {
    setRegistrationInfo({
      ...registrationInfo,
      [e.target.name]: e.target.value
    })
  }

  const hasNumber = (myString) => {
    return /\d/.test(myString);
  }

  const onClickRegister = () => {
    setRegisterSuccessMessage("");

    if (registrationInfo.password !== registrationInfo.confirmPassword) {
      setRegisterError("Passwords must match.");
      return;
    }

    if (registrationInfo.password < 6 || !hasNumber(registrationInfo.password)) {
      setRegisterError("Password must be at least 6 characters long and contain at least one number.")
      return;
    }

    setRegisterError("");

    const registerUser = async () => {
      const registerUserResponse = await api.auth.register({
        username: registrationInfo.username,
        password: registrationInfo.password
      });

      if (registerUserResponse) {
        setRegisterSuccessMessage("New user created. Please login to begin playing.")
      }
    }

    registerUser().catch(err => {
      console.error(err)
      setRegisterError(err.response.data.message);
    });
    
  }

  const onClickBackToLogin = () => {
    navigate("/login");
  }

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClickRegister();
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
      {/* <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg" fill={Colors.Green} fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11z"/></svg>       */}
      <div style={{ display: "flex", flexDirection: "column", padding: "10px", alignItems: "center" }}>
        <FadingInputWrapper $inputopacity={inputOpacity}>
          <div style={{ color: Colors.Green, fontSize: "16px" }}>Username</div>
          <FadingInput 
            type="text" 
            onChange={onChangeInput}
            onKeyDown={onPressEnter}
            id="username" 
            name="username" 
            value={registrationInfo.username}
          />
        </FadingInputWrapper>
        <div style={{ height: 10 }}></div>
        <FadingInputWrapper $inputopacity={inputOpacity}>
          <div style={{ color: Colors.Green, fontSize: "16px" }}>Password</div>
          <FadingInput 
            type="password"
            onChange={onChangeInput}
            onKeyDown={onPressEnter}
            id="password" 
            name="password" 
            value={registrationInfo.password}
          />
        </FadingInputWrapper>
        <div style={{ height: 10 }}></div>
        <FadingInputWrapper $inputopacity={inputOpacity}>
          <div style={{ color: Colors.Green, fontSize: "16px" }}>Confirm Password</div>
          <FadingInput 
            type="password"
            onChange={onChangeInput}
            onKeyDown={onPressEnter}
            id="confirmPassword" 
            name="confirmPassword" 
            value={registrationInfo.confirmPassword}
          />
        </FadingInputWrapper>
      </div>
      {registerError.length > 0 &&
        <div style={{ color: Colors.Red, marginTop: "10px", marginBottom: "10px" }}>{registerError}</div>
      }
      {registerSuccessMessage.length > 0 &&
        <div style={{ color: Colors.Green, marginTop: "10px", marginBottom: "10px" }}>{registerSuccessMessage}</div>
      }
      <div style={{ color: Colors.Green, width: "fit-content", margin: "auto" }}>
        <div style={{ height: Sizes.Medium }}></div>
        <HoverOuterItem opacity={opacity}>
          {"["}
          <FadingButton opacity={opacity} onClick={onClickRegister}>Register</FadingButton>
          {"]"}
        </HoverOuterItem>
        <div style={{ height: Sizes.Medium }}></div>
        <HoverOuterItem opacity={opacity}>
          {"["}
          <FadingButton opacity={opacity} onClick={onClickBackToLogin}>Back to Login</FadingButton>
          {"]"}
        </HoverOuterItem>
      </div>
    </div>
  )
}

const FadingInputWrapper = styled.div`
  text-align: left;
  font-size: ${Sizes.Large}px;
  opacity: ${props => props.$inputopacity};
  transition-duration: 1000ms;
`

const FadingInput = styled.input`
  padding: ${Sizes.Small}px; 
  color: ${Colors.Green}; 
  font-size: ${Sizes.Large}px;
  background-color: ${Colors.Black}; 
  border-color: ${Colors.Green}; 
  border-radius: ${Sizes.Small}px; 
  border-style: solid;
`

const FadingButton = styled.div`
  font-size: ${Sizes.Large}px;
  opacity: ${props => props.opacity};
  transition-duration: 1000ms;
  cursor: pointer;
  color: ${Colors.Green};
  padding: 0px 8px;
`

const HoverOuterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${props => props.opacity};
  transition: color 0.2s ease-in-out, opacity 0.1s ease-in-out;
  color: ${Colors.Black};
  margin: auto;
  width: fit-content;
  padding: 0px 8px;
  cursor: pointer;
  font-size: ${Sizes.Large}px;

  &:hover {
    color: ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`
