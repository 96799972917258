const Sizes = {
  ExtraSmall: 5,
  Small: 10,
  Medium: 20,
  Large: 25,
  ExtraLarge: 50,
  MainWidth: "700px",
  MainHeight: "550px",
  MobileHeight: "900px",
  InsideHeight: "400px"
}

export default Sizes