import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import styled from "styled-components"
import { useNavigate } from "react-router-dom";

export default function YouHaveGoneInsane(props) {
  const navigate = useNavigate();

  const onClickReturn = () => {
    navigate(props.returnLocation);
  }

  return (
    <div style={{ width: "100%", textAlign: "center", marginTop: Sizes.ExtraLarge}}>
      <div style={{ color: Colors.Red, fontSize: Sizes.ExtraLarge, fontWeight: 600 }}>
        YOU
      </div>
      <div style={{ color: Colors.Red, fontSize: Sizes.ExtraLarge, fontWeight: 600 }}>
        HAVE
      </div>
      <div style={{ color: Colors.Red, fontSize: Sizes.ExtraLarge, fontWeight: 600 }}>
        GONE
      </div>
      <div style={{ color: Colors.Red, fontSize: Sizes.ExtraLarge, fontWeight: 600 }}>
        INSANE.
      </div>
      <div style={{ color: Colors.Green, marginBottom: Sizes.ExtraLarge, marginTop: Sizes.Large }}>
        Find a way to restore your sanity, or wait for midnight to pass.
      </div>
      <ReturnButton onClick={onClickReturn}>RETURN</ReturnButton>
    </div>
  )
}

const ReturnButton = styled.div`
  color: ${Colors.Black};
  background-color: ${Colors.Green};  
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: auto;
`