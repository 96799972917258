export const Traits = {
  None: "None",
  Researcher: "Researcher",
  Neurotic: "Neurotic",
  ConArtist: "Con Artist"
}

export const CON_ARTIST_DESCRIPTION = "Hear unique dialogue, get discounts at stores.";
export const RESEARCHER_DESCRIPTION = "Recover sanity more easily.";
export const NEUROTIC_DESCRIPTION = "Increased critical damage and chance when sanity is at 50 or below.";


export default Traits;