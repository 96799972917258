import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import cockpit from "../assets/npcs/cockpit2.png";
import SpeechText from "../components/SpeechText";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { api } from "../axios/api";
import { setUserInfo } from "../store/user";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquare } from "@fortawesome/free-regular-svg-icons";
import useIsMobile from "../hooks/useIsMobile";

export default function Shuttle() {
  const user = useSelector((state) => { return state.user});
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const questStarted = user.userInfo.activeQuest && user.userInfo.activeQuest.name === "shuttle";
  const [showShuttle, setShowShuttle] = useState(false);

  const hasPart1 = user.userInfo.activeQuest && user.userInfo.activeQuest.part1;
  const hasPart2 = user.userInfo.activeQuest && user.userInfo.activeQuest.part2;

  const questCompleted = hasPart1 && hasPart2;
  const questFinished = user.userInfo.completedQuests.includes("shuttle");

  const shuttleDialogue = {
    intro: "You come across an old, derelict space shuttle. With a bit of technical knowhow, you could get it up and running again.",
    secondary: "Mission accepted! You'll need to repair the ship's reactor, and it looks like it needs a new flight computer too.",
    final: "The shuttle whirrs and hums as you start it up. Time to ride this baby to the stars!"
  }

  const [text, setText] = useState(shuttleDialogue.intro);

  useEffect(() => {
    if (questFinished) {
      setShowShuttle(true);
      setText(shuttleDialogue.final);
    } else if (questStarted) {
      setShowShuttle(true);
      setText(shuttleDialogue.secondary);
    }
  }, [questStarted, questFinished]);

  const onClickBack = () => {
    navigate("/desert");
  }

  const onClickOK = () => {
    console.log("quest accepted");
    setText(shuttleDialogue.secondary);
    setShowShuttle(true);

    const startShuttleQuest = async () => {
      const startNewQuestResponse = await api.users.startNewQuest({
        name: "shuttle",
        part1: false,  // computer
        part2: false,  // reactor
      });

      if (!startNewQuestResponse || !startNewQuestResponse.data) {
        console.log("Error in startNewQuest()")
      }

      if (startNewQuestResponse && startNewQuestResponse.data) {
        dispatch(setUserInfo(startNewQuestResponse.data));
      }
    }
    startShuttleQuest().catch(err => console.error(err));
  }

  const onClickFinish = () => {
    setText(shuttleDialogue.final);

    const completeQuest = async () => {
      const completeQuestResponse = await api.users.completeQuest("shuttle");
  
      if (!completeQuestResponse || !completeQuestResponse.data) {
        console.log("Error in completeQuest()")
      }
  
      if (completeQuestResponse && completeQuestResponse.data) {
        dispatch(setUserInfo(completeQuestResponse.data));
      }
    }

    completeQuest().catch(err => console.error(err));
  }

  const onClickLiftoff = () => {
    navigate("/space");
  }

  return (
    <>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div className="page-title">
        Wrecked Space Shuttle
      </div>
      <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
        <div style={{ width: "300px", padding: "30px 20px" }}>
          <img style={{ width: "100%" }} src={cockpit} alt="cockpit"/>
          <div style={{ color: Colors.Green, height: "24px" }}><SpeechText text={text}/></div>
          {!questStarted && !questFinished &&
            <OKButton onClick={onClickOK}>
              OK
            </OKButton>
          }
        </div>
        <SlideInFromRight $visible={showShuttle}>
          {questStarted &&
            <>
              <h1 style={{ color: Colors.Green, width: "100%", textAlign: "center" }}>SHUTTLE</h1>
              <div>
                <ChecklistItem>
                  <FontAwesomeIcon icon={hasPart1 ? faSquareCheck : faSquare}></FontAwesomeIcon>
                  <ChecklistItemText>Computer</ChecklistItemText>
                </ChecklistItem>
                <ChecklistItem>
                  <FontAwesomeIcon icon={hasPart2 ? faSquareCheck : faSquare}></FontAwesomeIcon>
                  <ChecklistItemText>Reactor</ChecklistItemText>
                </ChecklistItem>
              </div>
            </>
          }
          {questCompleted &&
            <FinishButton onClick={onClickFinish}>FINISH!</FinishButton>
          }
          {questFinished &&
            <FinishButton onClick={onClickLiftoff}>LIFT OFF!!!!</FinishButton>
          }
        </SlideInFromRight>
      </div>
    </>
  )
}

const OKButton = styled.div`
  color: ${Colors.Green}; 
  border: 2px solid ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
  font-size: 24px;
  width: fit-content; 
  padding: ${Sizes.Small}px; 
  margin: 60px auto;
`

const SlideInFromRight = styled.div`
  transform: ${props => (props.$visible ? 'translateX(0)' : 'translateX(10%)')};
  opacity: ${props => props.$visible ? 1 : 0};
  transition: transform 0.2s ease-in-out, opacity 0.2s linear;
  width: 350px;
`;

const ChecklistItem = styled.div`
  margin: auto;
  color: ${Colors.Green};
  padding: 0px 4px 0px 24px;
  size: ${Sizes.Medium}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ChecklistItemText = styled.div`
  padding-left: 4px;
  size: 18px;
`

const FinishButton = styled.div`
  color: ${Colors.black}; 
  margin: 24px auto auto auto; 
  width: fit-content; 
  padding: ${Sizes.ExtraSmall}px ${Sizes.Small}px; 
  background-color: ${Colors.Green}; 
  cursor: pointer; 
  font-weight: 600;
`