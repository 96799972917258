import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import StatNames, { DEXTERITY_DESCRIPTION, ENDURANCE_DESCRIPTION, MIND_DESCRIPTION, STRENGTH_DESCRIPTION } from "../constants/StatNames";
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { api } from "../axios/api";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/user";

export default function LevelUp(props) {
  const dispatch = useDispatch()
  const [charPoints, setCharPoints] = useState(1);
  const [levelUpError, setLevelUpError] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const initialStats = {
    strength: props.userInfo.strength,
    dexterity: props.userInfo.dexterity,
    mind: props.userInfo.mind,
    endurance: props.userInfo.endurance,
  };
  const [levelUpStats, setLevelUpStats] = useState({
    strength: props.userInfo.strength,
    dexterity: props.userInfo.dexterity,
    mind: props.userInfo.mind,
    endurance: props.userInfo.endurance,
  });

  const StatDownArrow = (props) => {

    const onClickStatDown = () => {
      if (charPoints < 5 && levelUpStats[props.statName] > initialStats[props.statName]) {
        setLevelUpStats({
          ...levelUpStats,
          [props.statName]: levelUpStats[props.statName] - 1
        })
        setCharPoints(prevState => {
          return prevState + 1
        })
      }
    }
    
    return (
      <div style={{ cursor: "pointer" }} onClick={onClickStatDown}>
        <FontAwesomeIcon icon={faCaretLeft} color={Colors.Green} size="2x" id={props.statName}/>
      </div>
    )
  }

  const StatUpArrow = (props) => {

    const onClickStatUp = () => {
      if (charPoints > 0) {
        setLevelUpStats({
          ...levelUpStats,
          [props.statName]: levelUpStats[props.statName] + 1
        })
        setCharPoints(prevState => {
          return prevState - 1
        })
      }
    }

    return (
      <div style={{ cursor: "pointer" }} onClick={onClickStatUp}>
        <FontAwesomeIcon icon={faCaretRight} color={Colors.Green} size="2x" id={props.statName}/>
      </div>
    )
  }

  const onClickLevelUp = () => {
    setLevelUpError("");

    if (charPoints > 0) {
      setLevelUpError("You haven't allocated all of your points.");
      return;
    }

    const levelUp = async () => {
      let newLevel = props.userInfo.level + 1;
      var updateUserStatsResponse = await api.users.levelUp(props.userId, {
        level: newLevel,
        strength: levelUpStats.strength,
        dexterity: levelUpStats.dexterity,
        endurance: levelUpStats.endurance,
        mind: levelUpStats.mind
      });

      if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
        console.log("Something went wrong with updating the user's stats.")
      }

      if (updateUserStatsResponse && updateUserStatsResponse.data) {
        console.log(updateUserStatsResponse);
        dispatch(setUserInfo(updateUserStatsResponse.data))
      }
    }

    levelUp().catch(err => console.error(err));
  }

  const RenderSelectedAttributeDescription = () => {
    return (
      <div style={{ color: Colors.Green, width: "200px", textAlign: "left" }}>
        {selectedAttribute === "strength" && 
          <div>{STRENGTH_DESCRIPTION}</div>
        }
        {selectedAttribute === "dexterity" && 
          <div>{DEXTERITY_DESCRIPTION}</div>
        }
        {selectedAttribute === "mind" && 
          <div>{MIND_DESCRIPTION}</div>
        }
        {selectedAttribute === "endurance" && 
          <div>{ENDURANCE_DESCRIPTION}</div>
        }
      </div>
    )
  }

  return (
    <div style={{ padding: Sizes.ExtraLarge }}>
      <Column>
        <Row style={{ marginBottom: "30px", maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
          <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>LEVEL UP!!!</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>{charPoints}</div>
            <div style={{ color: Colors.Green, fontSize: Sizes.Small }}>points</div>
          </div>
        </Row>
        <Row>
          <div style={{ maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("strength")}>Strength</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Strength}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{levelUpStats.strength}</div>
                <StatUpArrow statName={StatNames.Strength}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("dexterity")}>Dexterity</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Dexterity}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{levelUpStats.dexterity}</div>
                <StatUpArrow statName={StatNames.Dexterity}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("mind")}>Mind</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Mind}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{levelUpStats.mind}</div>
                <StatUpArrow statName={StatNames.Mind}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("endurance")}>Endurance</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Endurance}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{levelUpStats.endurance}</div>
                <StatUpArrow statName={StatNames.Endurance}/>
              </Row>
            </Row>
          </div>
          {selectedAttribute &&
            <RenderSelectedAttributeDescription />
          }
        </Row>
        <Row style={{ height: "220px" }}>
          <div onClick={onClickLevelUp} style={{ color: Colors.Green, fontWeight: 700, fontSize: Sizes.Large, borderColor: Colors.Green, borderStyle: "solid", padding: Sizes.Small, borderWidth: 1, cursor: "pointer", marginTop: "auto" }}>LEVEL UP!!</div>
        </Row>
        {levelUpError.length > 0 && 
          <div style={{ color: Colors.Red, textAlign: "center", marginTop: "20px" }}>{levelUpError}</div>
        }
      </Column>
    </div>
  )
}

const Row = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const HoverBorderItem = styled.div`
  border: 2px solid ${Colors.Black};
  font-size: ${Sizes.Medium}px;
  transition: border 0.2s ease-in-out;
  color: ${Colors.Green};
  padding: 2px 6px;
  width: 120px;
  max-width: 300px;

  &:hover {
    border: 2px solid ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`