const StatNames = {
  Strength: "strength",
  Dexterity: "dexterity",
  Endurance: "endurance",
  Mind: "mind"
}

export const STRENGTH_DESCRIPTION = "Strength measures physical power. It adds modifiers to your melee attacks and increases your critical chance.";
export const DEXTERITY_DESCRIPTION = "Dexterity measures reflexes and agility. It adds modifiers to your ranged attacks, and increases your speed and evasiveness in battle.";
export const MIND_DESCRIPTION = "Mind measures willpower and intelligence. It adds modifiers to psychic and scientific attacks, and reduces the amount of sanity you lose.";
export const ENDURANCE_DESCRIPTION = "Endurance measures physical stamina and toughness. It adds a bonus to your hit points.";
export const SANITY_DESCRIPTION = "Sanity measures your ability to think rationally. Sanity can be lost in many different ways - fighting scary monsters, losing battles, or going to bed with 0 HP, to name a few. When your sanity runs out, it's game over. Each night at midnight, your sanity is restored to 100."

export default StatNames;