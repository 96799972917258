import React, { useRef, useEffect } from 'react';

const SandCanvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const resizeCanvas = () => {
      canvas.width = props.width;
      canvas.height = props.height;
      drawSand();
    };
    
    const drawSand = () => {
      const sandColor = '#808080'; // Slightly darker version of the sand color
      const sandGrains = 3000;
      
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous content
      
      for (let i = 0; i < sandGrains; i++) {
        let x = Math.random() * canvas.width;
        let y = Math.random() * canvas.height;
        let size = Math.random() * 2 + 1;
        
        ctx.fillStyle = sandColor;
        ctx.fillRect(x, y, size, size);
      }
    };

    resizeCanvas(); // Call resizeCanvas initially
  }, []);

  return (
    <canvas ref={canvasRef} style={{ display: "block", backgroundColor: "#b78e63" }}></canvas>
  );
};

export default SandCanvas;