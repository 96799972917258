import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import Traits, { CON_ARTIST_DESCRIPTION, NEUROTIC_DESCRIPTION, RESEARCHER_DESCRIPTION } from "../constants/Traits";
import { useState } from "react"
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DEXTERITY_DESCRIPTION, ENDURANCE_DESCRIPTION, MIND_DESCRIPTION, STRENGTH_DESCRIPTION, SANITY_DESCRIPTION } from "../constants/StatNames";

export default function Mirror() {
  const user = useSelector((state) => { return state.user});
  const navigate = useNavigate();
  const isNeurotic = user.userInfo.trait === Traits.Neurotic;
  const isConArtist = user.userInfo.trait === Traits.ConArtist;
  const isResearcher = user.userInfo.trait === Traits.Researcher;

  const [selectedAttribute, setSelectedAttribute] = useState("");

  const onClickBack = () => {
    navigate('/hideout');
  }


  const RenderSelectedAttributeDescription = () => {
    return (
      <div style={{ color: Colors.Green, width: "200px", textAlign: "left" }}>
        {selectedAttribute === "strength" && 
          <div>{STRENGTH_DESCRIPTION}</div>
        }
        {selectedAttribute === "dexterity" && 
          <div>{DEXTERITY_DESCRIPTION}</div>
        }
        {selectedAttribute === "mind" && 
          <div>{MIND_DESCRIPTION}</div>
        }
        {selectedAttribute === "endurance" && 
          <div>{ENDURANCE_DESCRIPTION}</div>
        }
        {selectedAttribute === "sanity" && 
          <div>{SANITY_DESCRIPTION}</div>
        }
      </div>
    )
  }

  return (
    <>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div style={{ paddingLeft: Sizes.ExtraLarge, paddingRight: Sizes.ExtraLarge, paddingTop: Sizes.Medium }}>
        <Column>
          <Row style={{ marginBottom: "30px", maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
            <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>{user.userInfo.username}<span style={{ paddingLeft: 80 }}>Level {user.userInfo.level}</span></div>
          </Row>
          <Row style={{ maxHeight: "150px" }}>
            <div style={{ maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
              <Row>
                <HoverBorderItem onClick={() => setSelectedAttribute("strength")}>Strength</HoverBorderItem>
                <Row>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{user.userInfo.strength}</div>
                </Row>
              </Row>
              <Row>
                <HoverBorderItem onClick={() => setSelectedAttribute("dexterity")}>Dexterity</HoverBorderItem>
                <Row>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{user.userInfo.dexterity}</div>
                </Row>
              </Row>
              <Row>
                <HoverBorderItem onClick={() => setSelectedAttribute("mind")}>Mind</HoverBorderItem>
                <Row>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{user.userInfo.mind}</div>
                </Row>
              </Row>
              <Row>
                <HoverBorderItem onClick={() => setSelectedAttribute("endurance")}>Endurance</HoverBorderItem>
                <Row>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{user.userInfo.endurance}</div>
                </Row>
              </Row>
              <Row>
                <HoverBorderItem onClick={() => setSelectedAttribute("sanity")}>Sanity</HoverBorderItem>
                <Row>
                  <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{user.userInfo.sanity}</div>
                </Row>
              </Row>
            </div>
            {selectedAttribute &&
              <RenderSelectedAttributeDescription />
            }
          </Row>
          <Row style={{ height: "100px" }}>
            <div style={{ maxWidth: "400px", minWidth: "250px", marginRight: "auto", marginTop: "20px" }}>
              <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>Background</div>
              {isConArtist &&
                <TraitRow>
                  <div style={{ marginLeft: Sizes.Small }}>
                    <div style={{ color: Colors.Green, fontWeight: 600 }}>Con Artist</div>
                    <div style={{ color: Colors.Green }}>{CON_ARTIST_DESCRIPTION}</div>
                  </div>
                </TraitRow>
              }
              {isResearcher &&
                <TraitRow>
                  <div style={{ marginLeft: Sizes.Small }}>
                    <div style={{ color: Colors.Green, fontWeight: 600 }}>Researcher</div>
                    <div style={{ color: Colors.Green }}>{RESEARCHER_DESCRIPTION}</div>
                  </div>
                </TraitRow>
              }
              {isNeurotic &&
                <TraitRow>
                  <div style={{ marginLeft: Sizes.Small }}>
                    <div style={{ color: Colors.Green, fontWeight: 600 }}>Neurotic</div>
                    <div style={{ color: Colors.Green }}>{NEUROTIC_DESCRIPTION}</div>
                  </div>
                </TraitRow>
              }
            </div>
          </Row>
          <Row style={{ height: "120px" }}>
            <div style={{ maxWidth: "400px", minWidth: "250px", marginRight: "auto", marginTop: "20px" }}>
              <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>Other Stats</div>
              <TraitRow>
                <div style={{ marginLeft: Sizes.Small }}>
                  <div style={{ color: Colors.Gold }}>Gold: {user.userInfo.gold} G</div>
                  <div style={{ color: Colors.Green }}>Current Experience: {user.userInfo.exp}</div>
                  <div style={{ color: Colors.Green }}>Experience Needed: {user.userInfo.expNeeded}</div>
                </div>
              </TraitRow>
            </div>
          </Row>
        </Column>
      </div>
    </>
  )
}

const Row = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
`

const TraitRow = styled.div`
  margin-top: 10px;
  display: flex; 
  flex-direction: row; 
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const HoverBorderItem = styled.div`
  border: 2px solid ${Colors.Black};
  font-size: ${Sizes.Medium}px;
  transition: border 0.2s ease-in-out;
  color: ${Colors.Green};
  padding: 2px 6px;
  width: 120px;
  max-width: 300px;

  &:hover {
    border: 2px solid ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`