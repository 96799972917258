import Colors from "../constants/Colors"
import styled from "styled-components"

export default function HoverItem(props) {
  return (
    <HoverOuterItem onClick={() => props.onClick()}>
      {"["}<HoverInnerItem>{props.text}</HoverInnerItem>{"]"}
    </HoverOuterItem>
  )
}

const HoverInnerItem = styled.div`
  color: ${Colors.Green};
  padding: 0px 8px;
  font-size: 20px;
`

const HoverOuterItem = styled.div`
  display: flex;
  flex-direction: row;
  transition: color 0.2s ease-in-out;
  color: ${Colors.Black};
  margin: auto;
  width: fit-content;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`