import Colors from "../constants/Colors"
import Sizes from "../constants/Sizes"
import Traits, { CON_ARTIST_DESCRIPTION, NEUROTIC_DESCRIPTION, RESEARCHER_DESCRIPTION } from "../constants/Traits";
import StatNames, { DEXTERITY_DESCRIPTION, ENDURANCE_DESCRIPTION, MIND_DESCRIPTION, STRENGTH_DESCRIPTION } from "../constants/StatNames";
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";
import { api } from "../axios/api";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/user";
import useIsMobile from "../hooks/useIsMobile";

export default function CharacterCreation(props) {
  const dispatch = useDispatch()
  const isMobile = useIsMobile();
  const [charPoints, setCharPoints] = useState(5);
  const [newCharError, setNewCharError] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [newCharStats, setNewCharStats] = useState({
    strength: 0,
    dexterity: 0,
    mind: 0,
    endurance: 0,
    trait: Traits.None
  });

  const StatDownArrow = (props) => {

    const onClickStatDown = () => {
      if (charPoints < 5 && newCharStats[props.statName] > 0) {
        setNewCharStats({
          ...newCharStats,
          [props.statName]: newCharStats[props.statName] - 1
        })
        setCharPoints(prevState => {
          return prevState + 1
        })
      }
    }
    
    return (
      <div style={{ cursor: "pointer" }} onClick={onClickStatDown}>
        <FontAwesomeIcon icon={faCaretLeft} color={Colors.Green} size="2x" id={props.statName}/>
      </div>
    )
  }

  const StatUpArrow = (props) => {

    const onClickStatUp = () => {
      if (charPoints > 0 && newCharStats[props.statName] < 5) {
        setNewCharStats({
          ...newCharStats,
          [props.statName]: newCharStats[props.statName] + 1
        })
        setCharPoints(prevState => {
          return prevState - 1
        })
      }
    }

    return (
      <div style={{ cursor: "pointer" }} onClick={onClickStatUp}>
        <FontAwesomeIcon icon={faCaretRight} color={Colors.Green} size="2x" id={props.statName}/>
      </div>
    )
  }

  const TraitRadio = (props) => {
    const traitSelected = props.trait === newCharStats.trait;
    const onClickTrait = () => {
      setNewCharStats({
        ...newCharStats,
        trait: props.trait
      })
    }
    
    return (
      <div style={{ cursor: "pointer" }} onClick={onClickTrait}>
        {traitSelected ? <FontAwesomeIcon icon={faCircleDot} color={Colors.Green} /> : <FontAwesomeIcon icon={faCircle} color={Colors.Green} />}
      </div>
    )
  }

  const onClickProceed = () => {
    setNewCharError("");

    if (charPoints > 0) {
      setNewCharError("You haven't allocated all of your points.");
      return;
    }
    
    if (newCharStats.trait === Traits.None) {
      setNewCharError("Please select a trait.");
      return;
    }

    const updateStats = async () => {
      var updateUserStatsResponse = await api.users.updateUserStats(props.userId, {
        level: 1,
        strength: newCharStats.strength,
        dexterity: newCharStats.dexterity,
        endurance: newCharStats.endurance,
        mind: newCharStats.mind,
        trait: newCharStats.trait
      });

      if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
        console.log("Something went wrong.")
      }

      if (updateUserStatsResponse && updateUserStatsResponse.data) {
        dispatch(setUserInfo(updateUserStatsResponse.data))
      }
    }

    updateStats().catch(err => console.error(err));
  }

  const RenderSelectedAttributeDescription = () => {
    return (
      <div style={{ color: Colors.Green, width: "200px", textAlign: "left" }}>
        {selectedAttribute === "strength" && 
          <div>{STRENGTH_DESCRIPTION}</div>
        }
        {selectedAttribute === "dexterity" && 
          <div>{DEXTERITY_DESCRIPTION}</div>
        }
        {selectedAttribute === "mind" && 
          <div>{MIND_DESCRIPTION}</div>
        }
        {selectedAttribute === "endurance" && 
          <div>{ENDURANCE_DESCRIPTION}</div>
        }
      </div>
    )
  }

  return (
    <div style={{ padding: Sizes.ExtraLarge }}>
      <Column>
        <Row style={{ marginBottom: "30px", maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
          <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>New Character</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>{charPoints}</div>
            <div style={{ color: Colors.Green, fontSize: Sizes.Small }}>points</div>
          </div>
        </Row>
        <Row>
          <div style={{ maxWidth: "600px", minWidth: "250px", marginRight: "auto" }}>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("strength")}>Strength</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Strength}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{newCharStats.strength}</div>
                <StatUpArrow statName={StatNames.Strength}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("dexterity")}>Dexterity</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Dexterity}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{newCharStats.dexterity}</div>
                <StatUpArrow statName={StatNames.Dexterity}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("mind")}>Mind</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Mind}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{newCharStats.mind}</div>
                <StatUpArrow statName={StatNames.Mind}/>
              </Row>
            </Row>
            <Row>
              <HoverBorderItem onClick={() => setSelectedAttribute("endurance")}>Endurance</HoverBorderItem>
              <Row>
                <StatDownArrow statName={StatNames.Endurance}/>
                <div style={{ color: Colors.Green, fontSize: Sizes.Large, paddingLeft: Sizes.Small, paddingRight: Sizes.Small }}>{newCharStats.endurance}</div>
                <StatUpArrow statName={StatNames.Endurance}/>
              </Row>
            </Row>
          </div>
          {selectedAttribute &&
            <RenderSelectedAttributeDescription />
          }
        </Row>
        <Row style={{ height: "220px" }}>
          <div style={{ maxWidth: "400px", minWidth: "250px", marginRight: "auto", marginTop: "20px" }}>
            <div style={{ color: Colors.Green, fontSize: Sizes.Large, fontWeight: 700 }}>Background</div>
            <TraitRow>
              <TraitRadio trait={Traits.ConArtist}/>
              <div style={{ marginLeft: Sizes.Small }}>
                <div style={{ color: Colors.Green, fontWeight: 600 }}>Con Artist</div>
                <div style={{ color: Colors.Green }}>{CON_ARTIST_DESCRIPTION}</div>
              </div>
            </TraitRow>
            <TraitRow>
              <TraitRadio trait={Traits.Researcher}/>
              <div style={{ marginLeft: Sizes.Small }}>
                <div style={{ color: Colors.Green, fontWeight: 600 }}>Researcher</div>
                <div style={{ color: Colors.Green }}>{RESEARCHER_DESCRIPTION}</div>
              </div>
            </TraitRow>
            <TraitRow>
              <TraitRadio trait={Traits.Neurotic}/>
              <div style={{ marginLeft: Sizes.Small }}>
                <div style={{ color: Colors.Green, fontWeight: 600 }}>Neurotic</div>
                <div style={{ color: Colors.Green }}>{NEUROTIC_DESCRIPTION}</div>
              </div>
            </TraitRow>
          </div>
          {!isMobile &&
            <ProceedButton onClick={onClickProceed} style={{  marginTop: "auto" }}>Proceed</ProceedButton>
          }
        </Row>
        {isMobile &&
          <Row>
            <ProceedButton onClick={onClickProceed} style={{ marginTop: "40px" }}>Proceed</ProceedButton>
          </Row>
        }
        {newCharError.length > 0 && 
          <div style={{ color: Colors.Red, textAlign: "center", marginTop: "20px" }}>{newCharError}</div>
        }
      </Column>
    </div>
  )
}

const Row = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
`

const TraitRow = styled.div`
  margin-top: 10px;
  display: flex; 
  flex-direction: row; 
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const HoverBorderItem = styled.div`
  border: 2px solid ${Colors.Black};
  font-size: ${Sizes.Medium}px;
  transition: border 0.2s ease-in-out;
  color: ${Colors.Green};
  padding: 2px 6px;
  width: 120px;
  max-width: 300px;

  &:hover {
    border: 2px solid ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`

const ProceedButton = styled.div`
  color: ${Colors.Green}; 
  font-weight: 700; 
  font-size: ${Sizes.Large}px;
  border-color: ${Colors.Green}; 
  border-style: solid; 
  padding: ${Sizes.Small}px; 
  border-width: 1px; 
  cursor: pointer;
`