import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { useSelector } from "react-redux";
import SpeechText from "../components/SpeechText";
import Traits from "../constants/Traits";
import { getRandomIntMinMax } from "../utilities/helpers";
import { api } from "../axios/api";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../store/user";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Library(props) {
  const user = useSelector((state) => { return state.user});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isResearcher = user.userInfo.trait === Traits.Researcher;
  const hasLitCigaretteEquipped = user.userInfo.loadOut.head && user.userInfo.loadOut.head.id === 1;
  const hasDiaperEquipped = user.userInfo.loadOut.body && user.userInfo.loadOut.body.id === 12;
  const booksRead = user.userInfo.booksRead;
  const hasBooksToRead = booksRead < 5;
  // do these computations ahead of time
  // gain 10 (variable) sanity upon success
  // lose 5 (variable) sanity upon failure
  const sanityGained = hasLitCigaretteEquipped ? 12 : 10;
  const sanityLost = hasDiaperEquipped ? 3 : 5;

  const textSource =  {
    intro: "You step into the dusty library. Ancient tomes and scrolls from every source imaginable line the walls.",
    success: "You successfully pronounce each word in a healing mantra. Part of your sanity has been restored!",
    nothing: "You attempt to read a scroll, but it is unintelligible to you.",
    failure: "You mistakenly read a cursed passage out loud! Demonic spirits chase you through the halls of the library. Your sanity has been reduced."
  }

  const [text, setText] = useState(textSource.intro);

  const onClickBack = () => {
    navigate("/desert");
  }

  // reduce the user's sanity and preserve their gold
  // or 
  // save user's new sanity and less gold
  const updateUserStatsAfterAction = async (result) => {

    let newBooksRead = booksRead + 1;
    let payloadForUpdateUserStats = {};
    // if nothing, payload is only gaining a book.
    if (result === "nothing") {
      payloadForUpdateUserStats = {
        booksRead: newBooksRead,
      }
    // if success, payload is gaining a book, 
    // and gaining 10 sanity
    } else if (result === "success") {
      let newSanity = user.userInfo.sanity + sanityGained;

      payloadForUpdateUserStats = {
        sanity: newSanity,
        booksRead: newBooksRead
      }
    // if failure, payload is gaining a book, 
    // and losing 10 sanity
    } else if (result === "failure") {
      let newSanity = user.userInfo.sanity - sanityLost;
      
      payloadForUpdateUserStats = {
        sanity: newSanity,
        booksRead: newBooksRead
      }
    } else {
      console.log("Must specify an action");
      return;
    }


    var updateUserStatsResponse = await api.users.updateUserStats(user.userInfo._id, payloadForUpdateUserStats);

    if (!updateUserStatsResponse || !updateUserStatsResponse.data) {
      console.log("Something went wrong.")
    }

    if (updateUserStatsResponse && updateUserStatsResponse.data) {
      console.log(updateUserStatsResponse);
      dispatch(setUserInfo(updateUserStatsResponse.data));
    }
  }

  const onClickSearch = () => {
    let result = "nothing";
    if (booksRead >= 5) {
      return;
    }

    let bookRoll = getRandomIntMinMax(1,100);

    if (isResearcher) {
      bookRoll += 10;
    }
    
    if (bookRoll < 20) {
      setText(textSource.failure);
      result = "failure";
    } else if (bookRoll >= 80) {
      setText(textSource.success);
      result = "success";
    } else {
      setText(textSource.nothing);
    }
    updateUserStatsAfterAction(result).catch(err => console.error(err));
    props.setNeedsUpdate(true);
  }

  return (
    <div>
      <div onClick={onClickBack} style={{ color: Colors.Green, fontSize: Sizes.Medium, margin: Sizes.Small, cursor: "pointer" }}>
        {"<<"}<span style={{ textDecoration: "underline", paddingLeft: Sizes.Small }}>BACK</span>
      </div>
      <div className="page-title">
        Ancient Library
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "300px", padding: "20px 20px", margin: "auto" }}>
          {/* <img style={{ width: "100%" }} src={pharmacist} alt="pharmacist"/> */}
          <div style={{ color: Colors.Green, height: "24px" }}><SpeechText text={text}/></div>
        </div>
        {hasBooksToRead &&
          <div style={{ width: "300px", margin: "auto", marginTop: Sizes.ExtraLarge }}>
            <HoverOuterItem onClick={() => onClickSearch()}>
              {"["}<HoverInnerItem>1. Attempt to read from an ancient book. ({booksRead}/5)</HoverInnerItem>{"]"}
            </HoverOuterItem>
          </div> 
        }
      </div>

    </div>
  )
}


const HoverInnerItem = styled.div`
  color: ${Colors.Green};
  padding: 8px 8px;
  width: 360px;
  font-size: 20px;
  font-weight: 400;
`

const HoverOuterItem = styled.div`
  display: flex;
  flex-direction: row;
  transition: color 0.2s ease-in-out;
  color: ${Colors.Black};
  margin: auto;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;

  &:hover {
    color: ${Colors.Green};  /* Change to the desired highlight color */
    cursor: pointer;
  }
`