import { useNavigate } from "react-router-dom";
import Sizes from "../constants/Sizes";
import NightSky from "../components/NightSkyCanvas";
import moon from "../assets/locations/moon2.png";
import shuttle from "../assets/locations/shuttle.png";
import dig_site from "../assets/locations/dig_site.png";
import draculas_moon_base from "../assets/locations/draculas_moon_base.png";
import IconContainer from "../components/IconContainer";
import useIsMobile from "../hooks/useIsMobile";

export default function Moon() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onClickShuttle = () => {
    navigate("/desert");
  }

  const onClickDigSite = () => {
    navigate("/dig-site");
  }

  const onClickDraculasMoonBase = () => {
    navigate("/draculas-moon-base");
  }

  return (
    <>
      <div style={isMobile ? { position: "relative", overflowX: "scroll" } : {position: "relative", height: Sizes.MainHeight }}>
        {/* <div style={{ color: Colors.Green }}>Hello Moon</div>
        <div style={{ color: Colors.Green }}>Dracula's Moon Base</div>
        <div style={{ color: Colors.Green }}>Mysterious Dig Site</div> */}
        <NightSky width={694} height={550} style={{ position: "relative", overflow: "auto" }}/>
        <div style={{ position: "absolute", top: 40, left: 100 }}>
          <img alt="moon" style={{ height: "auto", width: "440px" }} src={moon}></img>
        </div>
        <IconContainer $top={80} $left={158} onClick={onClickShuttle}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={shuttle}></img>
          <div className="hover-text">Space Shuttle</div>
        </IconContainer>
        <IconContainer $top={290} $left={204} onClick={onClickDigSite}>
          <img alt="back" style={{ height: "auto", width: "96px" }} src={dig_site}></img>
          <div className="hover-text">Mysterious Dig Site</div>
        </IconContainer>
        <IconContainer $top={92} $left={333} onClick={onClickDraculasMoonBase}>
          <img alt="back" style={{ height: "auto", width: "144px" }} src={draculas_moon_base}></img>
          <div className="hover-text">Dracula's Moon Base</div>
        </IconContainer>
      </div>
    </>
  )
}