import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import city_fill from "../assets/locations/city_fill.png"

const CityCanvas = (props) => {
  const canvasRef = useRef(null);
  const lineWidth = 4;
  const numPoints = 21; // Number of jagged points
  const numPoints2 = 36;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = props.width;
      canvas.height = props.height;

      // Define jagged line properties
      const step = canvas.width / (numPoints - 1); // Horizontal step between points

      const step2 = canvas.width / (numPoints2 - 1);

      // Define jagged line 1 properties
      const lineColor1 = '#aaaaaa';
      let points1 = generateJaggedLine(numPoints, step, canvas.height * 0.1, canvas.height * 0.3);

      // Define jagged line 2 properties
      const lineColor2 = '#cccccc';
      let points2 = generateJaggedLine(numPoints2, 100, canvas.height * 0.35, canvas.height * 0.6);

      // Define jagged line 3 properties
      const lineColor3 = '#888888';
      let points3 = generateJaggedLine(numPoints, step, canvas.height * 0.65, canvas.height * 0.95);

      // Draw skyline 1
      drawSkyline(ctx, points1, lineColor1, canvas, false);

      // Draw jagged line 2
      drawSkyline(ctx, points2, lineColor2, canvas, false);

      // draw jagged line 3
      drawSkyline(ctx, points3, lineColor3, canvas, true);
    };

    resizeCanvas();
  }, []);

  // Function to generate jagged line points
  function generateJaggedLine(numberOfPoints, step, minY, maxY) {
    let points = [];
    for (let i = 0; i < numberOfPoints; i++) {
      let x = i * step;
      let y;
      
      let prevPoint = points[i - 1];

      // if there is a previous point, begin certain other behavior;
      if (prevPoint && i % 2 === 0) {
        y = prevPoint.y;
      } else if (prevPoint && i % 2 === 1) {
        x = prevPoint.x;
        y = Math.random() * (maxY - minY) + minY; // Random y within a range
      } else {
        y = Math.random() * (maxY - minY) + minY; // Random y within a range
      }
      points.push({ x, y });
    }
    // Sort points by x-coordinate
    points.sort((a, b) => a.x - b.x);
    return points;
  }

  // Function to draw jagged line
  function drawSkyline(ctx, points, color, canvas, bigif) {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    for (let i = 1; i < numPoints; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.stroke();
    // Fill below the line with black color
    ctx.lineTo(canvas.width, canvas.height);
    ctx.lineTo(0, canvas.height);
    ctx.closePath();

    if (bigif) {
      // create fillstyle
      const image = new Image();
      image.src = city_fill;
      image.onload = () => {
        console.log("image loaded");
        const pattern = ctx.createPattern(image, 'repeat');
        ctx.fillStyle = pattern; // Fill color same as line
        ctx.fill();
      }
    } else {
      ctx.fillStyle = color;
      ctx.fill();
    }
  }

  return <CanvasForCity ref={canvasRef} />;
};

export default CityCanvas;

const CanvasForCity = styled.canvas`
  background: rgba(0,0,0,0);
`