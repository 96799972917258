const Colors = {
  Black: "#000000",
  White: "#FFFFFF",
  Green: "#67b94b",
  LightGreen: "#14a50c",
  Gray: "#1B1B1B",
  Red: "#D30000",
  Gold: "#FFD700",
  LightGray: "#aaaaaa"
}

export default Colors;