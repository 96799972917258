import React, { useEffect, useRef } from 'react';

const PsychicCanvas = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let bubbles = [];

    function Bubble(x, y, size, speed, color) {
      this.x = x;
      this.y = y;
      this.size = size;
      this.speed = speed;
      this.color = color;
    }

    Bubble.prototype.draw = function() {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fillStyle = this.color;
      ctx.fill();
    }

    Bubble.prototype.update = function() {
      this.y -= this.speed;
      if (this.y < -this.size) {
        this.y = canvas.height + this.size;
      }
    }

    function createBubbles() {
      for (let i = 0; i < 25; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const size = Math.random() * 75 + 10;
        const speed = Math.random() * 0.4 + 0.4;
        const color = `rgba(${Math.floor(Math.random() * 75) + 175}, 0, ${Math.floor(Math.random() * 75) + 175}, ${Math.random() * 0.8 + 0.2})`;
        bubbles.push(new Bubble(x, y, size, speed, color));
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      bubbles.forEach(bubble => {
        bubble.draw();
        bubble.update();
      });
    }

    createBubbles();
    animate();

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      bubbles = [];
    };
  }, [width, height]);

  return <canvas ref={canvasRef} width={width} height={height} style={{ backgroundColor: 'black' }} />;
};

export default PsychicCanvas;